import { createSlice } from '@reduxjs/toolkit'

const items = [
    "personal_auto", "property", "commercial"
];


const createReducers = (items) => { 
    let reducers = {};
    items.map((item, index) => { 
        reducers[item] = (state, action) => { 
            state.index = index 
        }
    })
    return reducers;
}

export const tileDisplayIndex = createSlice({ 
    name: 'tileDisplayIndex', 
    initialState: { 
        index: 0
    }, 
    reducers: createReducers(items)
    
    
})

export const { 
    personal_auto, property, commercial
   
    } = tileDisplayIndex.actions; 

export default tileDisplayIndex.reducer