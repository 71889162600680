import React, {useState, useEffect, useRef} from 'react'
import Cookies from 'js-cookie'

function Merge_records({checkedHouseholds, setCurrentPopup, api, fetchData, currentPage, searchTerm, toDate, fromDate}) {
    
    const [householdOne, setHouseholdOne] = useState();
    const [householdTwo, setHouseholdTwo] = useState();
    const selectionOne = useRef({});
    const selectionTwo = useRef({});
   
    useEffect( ()=>{

        const fetchData = async() => { 
            const household_id_arr = [...checkedHouseholds];
            const data_one  = await getHouseholdData(household_id_arr[0]);
            const data_two  = await getHouseholdData(household_id_arr[1]);
            setHouseholdOne(data_one[0]);
            setHouseholdTwo(data_two[0]);
        }

        fetchData();
    },[checkedHouseholds])


    const getHouseholdData = async(id) => { 
        const response = await fetch(`${api}/api/household?id=${id}`,{
            headers: { 
                Authorization : Cookies.get('jwt')
            }
    })
        let data = await response.json();

        return data
    }

    const emptyStringToNull = (selectionObj) => { 
      //Convert all the empty string values into null
      for(let key in selectionObj.current){ 
        if(selectionObj.current[key] === ""){ 
          selectionObj.current[key] = null;
        }
      }
    }

    const mergeObjs = () => { 
      //Merge objs using spread operator 
      const mergedObj = {...selectionOne, ...selectionTwo}
      return mergedObj.current;
    }

    const handleClick = async() => { 
     const response = await mergeUpdates();
     if(response.status !== 200) return alert("Error merging households!");

     await fetchData(currentPage, searchTerm, toDate, fromDate);
     setCurrentPopup(null)

      
    }

    const mergeUpdates = async() => { 
      emptyStringToNull(selectionOne);
      emptyStringToNull(selectionTwo);
      const mergedObj =  mergeObjs();
      const response = await fetch(`${api}/api/household/merge`, { 
        method: "PUT", 
        headers: { 
          "Content-Type": "application/json"
        }, 
        body: JSON.stringify({
          merge_household_id: selectionOne.current.household_id, 
          keep_household_id: selectionTwo.current.household_id
        })

      })
      return response;
    }
    
  return (
  <>

   <div className='background-color-full-dark border-radius-10px  popup z-index  '  >
     
     <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)}>❌</button></div>
         <div className='flex-container-el padding-10px ' > 
            <h3 className='padding-10px'>Merge Records </h3>
            <div className='flex-across justify-content-space-between height-half'>
             <div>
               <h3 className='color-red'>Merge</h3>
               {householdOne && Object.entries(householdOne).map(([key, value], index) => (
                <div className='flex-column-no-ho-centered'>
                <h4>{key}</h4>
                  <select className='margin-top-10px green-submit-btn ' ref = {el => el ? selectionOne.current[key] = el.value : null}> 
                    <option value = {value}>{value}</option>
                    
                  </select>
                </div>
               ))}
           
             </div>
 
             <div className=' margin-left-200px flex-across justify-content-space-between'>
            <div>
              <h3 className ="color-orange">Keep</h3>
                {householdOne && Object.entries(householdTwo).map(([key, value]) => (
                    <div className='flex-column-no-ho-centered'>
                    <h4>{key}</h4>
                    <select className='margin-top-10px green-submit-btn ' ref = {el => el ? selectionTwo.current[key] = el.value : null}>
                        <option value = {value}>{value}</option>
                      
                    </select>
                    </div>
                ))}
               </div>
             </div>
             </div>
               
         <button type = "button" className='button-default green-submit-btn margin-top-20px max-width padding-10px' onClick={() => handleClick()} >Merge Records</button>
         
     </div>
 </div>
  </>
  )
}

export default Merge_records
