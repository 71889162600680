import { createSlice } from '@reduxjs/toolkit'

export const firstSlice = createSlice({ 
    name: 'firstSlice',
    initialState: { 
        toggle:true
    }, 
    reducers: { 
        set_true:( state, action) => { 
            if(state.toggle === false){  
                state.toggle = true 
            }else { 
                state.toggle = false;
            }
         
        }, 
        
    }
})

export const { set_true} = firstSlice.actions
export default firstSlice.reducer