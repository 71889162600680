import React from 'react'

function StudyGuides() {
  return (
    <div className='secondary-color'>
        <h3 className='secondary-color'>Study Guides</h3> 
        <p className='secondary-color'>Select one of our study guides to prepare for your exam!</p>
        <a href ="/question-bank" className='secondary-color'>Practice for your exam</a>
    </div>
  
  )
}

export default StudyGuides
