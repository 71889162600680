import React from 'react'

function News() {
  return (
    <div>
    <h3 className='secondary-color'>News</h3> 
    <p className='secondary-color'>Latest news and updates</p>
</div>
  )
}

export default News;
