import React, {useRef, useState} from 'react'
import Cookies from 'js-cookie'

function EditDriver({driverData, setCurrentPopup, api, updateDrivers, getVehicles}) {
   
    const driver_id = useRef();
    const first_name = useRef();
    const last_name = useRef();
    const relation = useRef();
    const dateOfBirth = useRef(); 
    const gender = useRef();
    const middle_name = useRef();
    
    
    const update_input_fields = () => { 
        if(driverData.length > 0 ){ 
            //To get the selected driver's obj data out of the array of objs
            let  selected_driver_data = driverData.find( driver=> driver.drivers_id === driver_id.current.value);
            first_name.current.value = selected_driver_data.firstname;
            last_name.current.value = selected_driver_data.lastname;
            relation.current.value = selected_driver_data.relation;
            dateOfBirth.current.value = selected_driver_data.dateOfBirth;
            gender.current.value = selected_driver_data.gender;
            middle_name.current.value = selected_driver_data.middlename !== 'null'  ? selected_driver_data.middlename : " "  ;
        }

    }

    const CREATE_UPDATED_DRIVER_OBJ = () => { 
        //Error handling for missing input fields
        if(first_name.current.value && last_name.current.value && relation.current.value && dateOfBirth.current.value && gender.current.value){ 
            let DRIVER_OBJ = { 
                driver_id: driver_id.current.value, 
                firstname: first_name.current.value, 
                lastname: last_name.current.value,
                relation: relation.current.value,
                dateOfBirth: dateOfBirth.current.value, 
                gender: gender.current.value,
                middlename: middle_name.current.value !== " "? middle_name.current.value : 'null'
            }
             return DRIVER_OBJ;
        }else { 
            alert('Missing required input field!');
        }
    }

    const FETCH_REQ = async(DRIVER_OBJ) => { 
        const response = await fetch(`${api}/api/driver`,{ 
            method : "PUT", 
            headers: {
                'Content-Type': 'application/json',
            }, 
            body : JSON.stringify({DRIVER_OBJ})
        })
        return response;
    }
 
    const POST_NEW_DRIVER_DATA = () => { 
        const driver_obj = CREATE_UPDATED_DRIVER_OBJ();

        if(driver_obj){ 
        const response = FETCH_REQ(driver_obj);
            if(response.status === 500){ 
                alert('Error updating driver');
            }else { 
                updateDrivers(Cookies.get('currentHouseholdId'))
                getVehicles();
                setCurrentPopup(null);
            }
        }
    }

    const DELETE_DRIVER = async() => { 
        const response = await fetch(`${api}/api/driver`, { 
            method: "DELETE", 
            headers: { 
                "Content-Type": "application/json"
            }, 
            body: JSON.stringify({
                driver_id: driver_id.current.value
             })
        })
        return response;
    }
     const DELETE_BTN = async() => { 
        const response = await DELETE_DRIVER();
        if(response.status === 200){
           return getVehicles(); 
         }
         return alert('Error deleting drivers!')
     }


     
    
  return (
    <>
   
    <div className='background-color-full-dark border-radius-10px popup '  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)}  >❌</button></div>
            <div className='flex-container-el padding-20px'> 
                <h3 className='text-align-left'> Edit Driver</h3>
                <label for = "firstname" className='background-color-full-dark text-align-left  '>Select Driver </label>
                <select  className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'  autocomplete="off" onClick={()=> update_input_fields() } ref = {driver_id} >
                    {driverData.length > 0 && driverData.map((driver) => ( 
                        <option value = {driver.drivers_id} > {driver.firstname}</option>
                    ))}
                </select>
                <label for = "lastname" className='background-color-full-dark text-align-left margin-top-10px '>First Name*</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'  autocomplete="off" ref = {first_name} />
                <label for = "lastname" className='background-color-full-dark text-align-left margin-top-10px '>Middle Name</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'  autocomplete="off" ref = {middle_name}/>
                <label for = "lastname" className='background-color-full-dark text-align-left margin-top-10px '>Last Name*</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'  autocomplete="off" ref = {last_name}/>
                <label for = "relation" className='background-color-full-dark text-align-left margin-top-10px'>Relation*</label>
                <select className='new-driver-input' id = "relation" ref = {relation}> 
                    <option>Self</option>
                    <option>Son</option>
                    <option>Mother</option>
                    <option>Father</option>
                    <option>Brother</option>
                    <option>Sister</option>
                    <option>Grandmother</option>
                    <option>Grandfather</option>
                    <option>Spouse</option>
                    <option>Daughter</option>
                    <option>Grandchild</option>
                </select>
                <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Date of Birth*</label>
                <input type = 'date' className='background-color-full-dark regularTextInput margin-top-5px new-driver-input max-width'  autocomplete="off" ref = {dateOfBirth}/>
                <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Gender*</label>
                <select  className=' background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'  autocomplete="off" ref = {gender}>
                    <option>Male</option>
                    <option>Female</option>
                </select>
                <button type = "button" class = "button-default green-submit-btn margin-top-20px max-width " onClick={() => POST_NEW_DRIVER_DATA()}  >Edit</button>
                <button type = "button" class = "button-default red-submit-btn margin-top-20px max-width " onClick={() => DELETE_BTN()}  >Delete</button>
        </div>
       </div>
    </>
  )
}

export default EditDriver
