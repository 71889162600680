import  {React, useRef} from 'react';
import Cookies from 'js-cookie';
function AddHomePolicyPopup({driverData, insurance_companies, POST_POLICY, fetchData, setCurrentPopup}) {

  const POLICY_NUMBER = useRef();
  const POLICY_STATUS = useRef();
  const POLICY_ADDRS = useRef();
  const POLICY_COMPANY = useRef();
  const EFFECTIVE_DATE = useRef();
  const TOTAL_PREM = useRef();
  const primary_driver = useRef();
  const comm_prem = useRef();
  const PREM_LENGTH = useRef();
  const EXP_DATE = useRef();
  
  
  const CREATE_HOME_POLICY_OBJ = () => { 
    if(POLICY_NUMBER.current.value && POLICY_STATUS.current.value && POLICY_ADDRS.current.value && POLICY_COMPANY.current.value && EFFECTIVE_DATE.current.value && EXP_DATE.current.value ){
      let monthly_prem = TOTAL_PREM.current.value / 12;
        let POLICY_OBJ = { 
            user_id: Cookies.get('userid'),
            policy_type: "Home",
            household_id: Cookies.get('currentHouseholdId'),
            policy_number: POLICY_NUMBER.current.value, 
            status: POLICY_STATUS.current.value, 
            address_line_2: POLICY_ADDRS.current.value,
            effective_date: EFFECTIVE_DATE.current.value,
            company: POLICY_COMPANY.current.value,
            total_prem: TOTAL_PREM.current.value, 
            monthly_prem: monthly_prem,
            primary_driver: primary_driver.current.value,
            expiration_date: EXP_DATE.current.value,
            comm_prem: comm_prem.current.value ? comm_prem.current.value : null,
            items: 1

        } 
        return POLICY_OBJ;
    } else { 
        alert('Missing a required field!')
        return;
    }
}

const CREATE_NEW_HOME_POLICY_BTN = async() => { 
    const POLICY_OBJ = CREATE_HOME_POLICY_OBJ();
    if(POLICY_OBJ){ 
        const response = await POST_POLICY(POLICY_OBJ);
        if(response.status === 500){ 
            alert('Policy Number invalid or already exists!')
        }else { 
            fetchData();
            setCurrentPopup(null)
        }

      }
}




  return (
    <> 
    <div className='background-color-full-dark border-radius-10px popup' id = "home-popup"  >
      <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick = {() => setCurrentPopup(null)} >❌</button></div>
        <div className='flex-container-el padding-20px'> 
        <h3 className='text-align-left margin-bottom-10'>Add Home Policy</h3>
        <label for = "lastname" className='background-color-full-dark text-align-left   margin-top-5px '>Policy Number*</label>
        <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width home_policy_input' id = "policy_number" ref={POLICY_NUMBER} autocomplete="off"/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Policy Status*</label>
        <select className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width  home_policy_input ' id = "status" autocomplete="off" ref={POLICY_STATUS} >
          <option value = "Active">Active</option>
          <option value = "Active">Cancelled</option>
          <option value = "Active">Pending Cancel</option>
          <option value = "Active">Terminated</option>
        </select>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Policy Address*</label>
        <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width home_policy_input ' id = "address_line_2" autocomplete="off" ref={POLICY_ADDRS}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Policy Company*</label>
        <select  className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width home_policy_input ' id = "company" autocomplete="off"  ref={POLICY_COMPANY}>
          {insurance_companies && insurance_companies.map(company => ( 
            <option  value = {company} >{company} </option>
          ))}
        </select>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Expiration Date*</label>
        <input type = 'date' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width'  autocomplete="off" ref={EXP_DATE}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Effective Date*</label>
        <input type = 'date' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width  home_policy_input' id = "effective_date" autocomplete="off" ref={EFFECTIVE_DATE} />
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Prem Length*</label>
        <select className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width' placeholder="$0.00"  autocomplete="off" ref={PREM_LENGTH}>
          <option value = '6'>6 Months</option>
          <option value = '12' >12 Months</option>
        </select>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Total Prem*</label>
        <input type = 'number' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width' placeholder="$0.00"  autocomplete="off" ref={TOTAL_PREM}/>
        <label className = 'text-align-left'>Primary Driver*</label>
        <select  className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width home_policy_input'  autocomplete="off"  ref={primary_driver}>
          {driverData && driverData.map((driver) => ( 
            <option value={driver.drivers_id}>{driver.full_name} </option>
          ))}
        </select>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Commissionable Prem</label>
        <input type = 'number' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width'  autocomplete="off" ref={comm_prem}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Date Bound</label>
        <span>{new Date().toLocaleDateString()}</span>
        <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width " id = "add=driver-submit-btn" onClick = {()=> CREATE_NEW_HOME_POLICY_BTN()} >Add new policy</button>
      </div>
    </div>
    </>
  )
}

export default AddHomePolicyPopup

