import React, {useRef} from 'react'

function AddJewelryCoverage({sendCoverageData, fetchCoverages, setCurrentCoveragePopup, policies}) {
    const belong_to_policy = useRef();
    const items = useRef();
    const limits = useRef();
    const wearer = useRef();
    const deductibles = useRef();

    const CREATE_JEWL_COV_OBJ = () => { 

        if(belong_to_policy.current.value && items.current.value ){ 
            let AUTO_COV_OBJ = {
                type: "jewelery",
                belong_to_policy: belong_to_policy.current.value,
                items: items.current.value,
                limits: limits.current.value,
                deductibles: deductibles.current.value,
                wearer: wearer.current.value,
            } 
            return AUTO_COV_OBJ;

        }else { 
            return alert('Missing Policy Number or Items!')
        }

    }

    const CREATE_NEW_JEWEL_COV_BTN = async() => { 
        const obj = CREATE_JEWL_COV_OBJ();
       
        const response = await sendCoverageData(obj); 
        if(response.status === 500 ){ 
            return alert('Error Creating New Coverage! Check connection!')
        }else { 
            fetchCoverages('jewelery', belong_to_policy.current.value);
            setCurrentCoveragePopup(null);
        }
    }
    



  return (
    <div className='background-color-full-dark border-radius-10px popup'   >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentCoveragePopup(null)} >❌</button></div>
            <div className='flex-container-el padding-20px'> 
                <h3 className='text-align-left margin-bottom-10'>Add Jewelery Coverage </h3>
                <label  className='background-color-full-dark text-align-left   margin-top-5px '>Policy Number:</label>
                <select className='background-color-full-dark regularTextInput margin-top-5px max-width ' ref={belong_to_policy} >
                {policies && policies.map(policy => (
                    <option values = {policy.policy_id}>{policy.policy_id}</option>
                )
                )}
                </select>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Items</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width' autocomplete="off" ref={items}/>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px'>Limits</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width' autocomplete="off" ref = {limits}/>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px'>Deductibles</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width'  autocomplete="off" ref= {deductibles}/>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px'>Wearer</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width'  autocomplete="off" ref={wearer}/>
                <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width"  onClick={() =>CREATE_NEW_JEWEL_COV_BTN()} >Add new coverage</button>
            </div>
      </div>
  )
}

export default AddJewelryCoverage
