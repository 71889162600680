import React from 'react'

function Knowledge_base() {
  return (
<div className='width-100-percent padding-10px-around  '>
        <h2 className='padding-10px-around'> Frequently Asked Questions</h2>
        <div className='flex space-between  width-100-percent' name = 'main-div '>
        
        


          
        </div>
     </div>
  )
}

export default Knowledge_base
