import React, { useState, useEffect } from 'react';
import {editHouseholdInfo} from '../fetches/fetch';
import Cookies from 'js-cookie';

function HouseholdInfo({ setCurrentPopup, householdData, getHouseholdData }) {
  const [formData, setFormData] = useState(householdData || {});

  // Update formData if householdData changes
  useEffect(() => {
    setFormData(householdData || {});
  }, [householdData])

  const handleInputChange = async(e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handleSubmitBtn = async() => { 
    const response = await editHouseholdInfo(Cookies.get('currentHouseholdId'), formData);
    if(response.status === 500){ 
        return alert('Error submitting button');
    }
    getHouseholdData();
    return setCurrentPopup(null);
    
  }

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
    ];


  return (
    <div className='background-color-full-dark border-radius-10px popup' id="add-driver-crud-ops">
      <div className='black-background text-align-right border-radius-top-10px'>
        <button type="button" className='popup-exit-button' onClick={() => setCurrentPopup(null)}>❌</button>
      </div>
      <div className='flex-container-el padding-20px'>
        <h3 className='text-align-left'>Edit Household Contact Info</h3>

        <label htmlFor="home_phone" className='background-color-full-dark text-align-left'>Phone</label>
        <input
          type='text'
          name='home_phone'
          className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'
          value={formData.home_phone || ''}
          onChange={handleInputChange}
        />

        <label htmlFor="address_line_one" className='background-color-full-dark text-align-left margin-top-10px'>Street</label>
        <input
          type='text'
          name='address_line_one'
          className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'
          value={formData.address_line_one || ''}
          onChange={handleInputChange}
        />

        <label htmlFor="city" className='background-color-full-dark text-align-left margin-top-10px'>City</label>
        <input
          type='text'
          name='city'
          className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'
          value={formData.city || ''}
          onChange={handleInputChange}
        />

        <label htmlFor="state" className='background-color-full-dark text-align-left margin-top-10px'>State</label>
        <select
          type='text'
          name='state'
          className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'
          value={formData.state || ''}
          onChange={handleInputChange}>
            {states && states.map(state => ( 
              <option>{state}</option>
            ))}
          </select>

        <label htmlFor="zip_code" className='background-color-full-dark text-align-left margin-top-10px'>Zip Code</label>
        <input
          type='text'
          name='zip_code'
          className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'
          value={formData.zip_code || ''}
          onChange={handleInputChange}
        />

        
        <label htmlFor="email" className='background-color-full-dark text-align-left margin-top-10px'>Email</label>
        <input
          type='text'
          name='email'
          className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'
          value={formData.email || ''}
          onChange={handleInputChange}
        />

        
        
        <label htmlFor="mailing_address" className='background-color-full-dark text-align-left margin-top-10px'>Mailing Adress</label>
        <input
          type='text'
          name='mailing_address'
          className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'
          value={formData.mailing_address || ''}
          onChange={handleInputChange}
        />

        <button type="button" className="button-default green-submit-btn margin-top-20px max-width" id="add-driver-submit-btn" onClick = {() => handleSubmitBtn()}>
          Edit Household Contact Info
        </button>
      </div>
    </div>
  );
}

export default HouseholdInfo;
