import React from 'react'

function Contact() {
  return (
    <div>
        <h3 className='secondary-color'>Contact</h3> 
        <p className='secondary-color'>Contact information here</p>
    </div>
  )
}

export default Contact;
