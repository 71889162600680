import React, { useEffect, useState} from 'react'
import NewTransaction from './policy_popups/NewTransactionPopup';
const { deleteTransaction} = require('./fetches/fetch');

function Transaction({setCurrentCoveragePopup, transactions, fetchTransactions, fetchData, currentPolicyId, userIdAndFullName}) {
  const [toggleNewTransaction, setToggleNewTransaction] = useState(false);

  const handleDeleteTransaction = async(transaction_id) => { 
    const response = await deleteTransaction(transaction_id);
    if(response.status === 500) return alert('Error deleting transaction'); 
    
    return await fetchTransactions(currentPolicyId);
  }
 
  return (
    
    <div className='overflow-wrapper'>
     <table>
        <th>Date</th>
        <th>Type</th>
        <th>Description</th>
        <th>Premium</th>
        <th>Producer</th>
        <tbody> 
          {transactions && transactions.map(transaction => ( 
            <tr>
              <td><span className = "cursor-pointer" onClick = {() => { handleDeleteTransaction(transaction.transaction_id)}}>🗑️</span>{new Date(transaction.date_created).toLocaleTimeString() + " " + new Date(transaction.date_created).toLocaleDateString('en-US', {timeZone: 'UTC'})}</td>
              <td>{transaction.type}</td>
              <td>{transaction.description}</td>
              <td>${transaction.premium}</td>
              <td>{userIdAndFullName[transaction.user_id]}</td>
            </tr>
          ))}
          
        </tbody> 

     </table>
     
     <button  type="button" className='max-width button-default margin-top-10' onClick = {() => setToggleNewTransaction(true) } >New Transaction</button>
     {toggleNewTransaction && <NewTransaction setToggleNewTransaction  = {setToggleNewTransaction}  userIdAndFullName ={ userIdAndFullName} currentPolicyId = {currentPolicyId} fetchTransactions={fetchTransactions} fetchData= {fetchData}/>}
    </div>
  )
}

export default Transaction
