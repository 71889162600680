import React from 'react'

function SelectCoverageType({setCurrentCoveragePopup }) {
  return (
    <div className='background-color-full-dark border-radius-10px popup ' >
    <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={()=> setCurrentCoveragePopup(null)}  >❌</button></div>
    <div className='flex-container-el padding-20px'> 
    <h3 className='text-align-left margin-bottom-10'>Select Coverage Type:</h3>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={()=> setCurrentCoveragePopup(1)}>Auto</button>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={()=> setCurrentCoveragePopup(2)}>Motorcycle</button>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={()=> setCurrentCoveragePopup(3)}>Jewelry</button>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={()=> setCurrentCoveragePopup(4)}>Home</button>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={()=> setCurrentCoveragePopup(5)}>Rental</button>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={()=> setCurrentCoveragePopup(6)}>PUP</button>
    </div>
</div>
  )
}

export default SelectCoverageType
