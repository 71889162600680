import React, {useRef} from 'react'
import Cookies from 'js-cookie'

function AddRentalCoverage({policies, setCurrentCoveragePopup, sendCoverageData, fetchCoverages}) {

    const PERSONAL_PROPERTY = useRef();
    const BELONG_TO_POLICY = useRef();

    const CREATE_RENTAL_OBJ = () => { 
        if(PERSONAL_PROPERTY.current.value){ 
            let obj = { 
                type : "rental", 
                
                belong_to_policy: BELONG_TO_POLICY.current.value, 
                personal_property: PERSONAL_PROPERTY.current.value
            }
            return obj;
        }else { 
            return alert('Missing input field!')
        }

    }

    const POST_NEW_RENTAL_COV = async() => { 
        const obj = CREATE_RENTAL_OBJ();
        console.log(obj)
        const response = await sendCoverageData(obj);
        if(response.status === 500){ 
            alert('Error Creating Rental Coverage!')
        }else { 
            fetchCoverages("rental", BELONG_TO_POLICY.current.value);
            setCurrentCoveragePopup(null);
        }

    }

  return (
    <div className='background-color-full-dark border-radius-10px popup'   >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentCoveragePopup(null)} >❌</button></div>
            <div className='flex-container-el padding-20px'> 
                <h3 className='text-align-left margin-bottom-10'>Add Rental Coverage </h3>
                <label  className='background-color-full-dark text-align-left   margin-top-5px '>Policy Number</label>
                <select className='background-color-full-dark regularTextInput margin-top-5px max-width'  ref = {BELONG_TO_POLICY}>
                {policies && policies.map(policy => (
                    <option values = {policy.policy_id}>{policy.policy_id}</option>
                )
                )}
                </select>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px'>Peronal Property</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width' autocomplete="off"  ref = {PERSONAL_PROPERTY} />
                <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width"  onClick = {()=> POST_NEW_RENTAL_COV()} >Add new coverage</button>
            </div>
  </div>
  )
}

export default AddRentalCoverage
