import React, {useEffect, useState} from 'react'
import Cookies from 'js-cookie';
import {patchCompleteTasks} from '../fetches/fetch'
function Calender_popup({eventObj, setTogglePopup, api, getTasks}) {
    const [driverOne, setDriverOne] = useState();

    useEffect(()=> {
        
        const displayDrivers = async() => { 
            const data = await getDrivers();
            setDriverOne(data[0]);
        }
        displayDrivers();

    },[])
    const deleteTask = async(eventObj) => { 
        const response = await fetch(`${api}/api/task?task_id=${eventObj[0]?.id}`, { 
            method: "DELETE"
        });

        return response;
    }

    const handleDelete = async() => { 
        const response = await deleteTask(eventObj); 
        if(response.status === 500) return alert("Error deleting task");
        await getTasks();
        setTogglePopup(false);

    }

    const getDrivers = async() => { 
        const response = await fetch(`${api}/api/household/drivers?id=${eventObj[0].household}`, { 
            headers: { 
            'Authorization': Cookies.get('jwt')
            }
        });
        const data = await response.json();
        return data;
    }
    
    const handleUpdateTask = async(task_id) => { 
        const response = await patchCompleteTasks(task_id); 
        if (response.status === 500) return alert("Error creating task!"); 
        await getTasks();
        return setTogglePopup(false);

    }

  return (
    <>

    <div className='background-color-full-dark border-radius-10px z-index width-400px word-wrap ' id = "household-driver-crud-ops" >
         <div className = 'black-background  text-align-right border-radius-top-10px z-index width-400px '><button type = "button" className='popup-exit-button' onClick={() => setTogglePopup(false)} >❌</button></div>
             <div className='flex-container-el width-400px padding-10px'> 
             <p className='background-color-full-dark text-align-left' ><h3 className='background-color-full-dark  '>Driver One </h3>{driverOne && driverOne.firstname + " " + driverOne.lastname }</p>
             <p className='background-color-full-dark text-align-left' ><h3 className='background-color-full-dark  '>Household_id</h3>{eventObj[0].household}</p>
             <p className='margin-top-10px background-color-full-dark  text-align-left  ' ><h3 className='background-color-full-dark  '>Description:</h3>{eventObj[0].title} </p>
             <p className='margin-top-10px background-color-full-dark text-align-left' ><h3 className='background-color-full-dark  '>Date:</h3>{eventObj[0].start.toLocaleString()}</p>
            {eventObj && eventObj[0].is_completed === false ? ( 
             <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width margin-top-10px' 
             onClick = { () =>  handleUpdateTask(eventObj[0].id)}>Complete?</button>)
             : <span className='color-orange'>Completed</span> }
            <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width margin-top-10px' onClick = { () =>  handleDelete()}>Delete</button>
         </div>
     </div>
  
    </>
  )
}

export default Calender_popup
