import React from 'react'
import Exam_page from '../testing_resources/exam_page';

function Question_bank() {
  return (
    <div className='text-align-right-new'><button type ="button" className='green-submit-btn margin-10px'onClick = {() => window.location.href = '/question-bank/new'}>Settings</button>
    <div  className='flex-container-el'>
      <h2 className='x'>Avaliable Tests</h2> 
      <div className='full-height-overflow-y flex-container-el'>
        <table className='width-75-percent'>
            <thead>
                <tr>
                <th>Name</th>
                <th> Date added</th>
                <th># Of Questions</th>
            </tr>
            </thead>
            <tbody >
            
               
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
                <tr>
                <td><u className='hover-cursor-pointer'>Entrance Exam</u></td>
                <td>6-18-2024</td>
                <td> 15</td>
                </tr>
            </tbody>
        </table>
       </div>
       <a href ="/lead-management" className='secondary-color'>Back To Agent Z</a>
    </div>
    </div>
  )
}

export default Question_bank;
