import React from 'react'

function Carriers() {
  return (
    <div>
        <h3 className='secondary-color'>Carriers</h3> 
        <p className='secondary-color'>Information about carriers</p>
    </div>
  )
}

export default Carriers
