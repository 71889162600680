import {React, useRef} from 'react'

export default function NewStatus({householdData, setCurrentPopup, currentCustomer, prospect, formerCustomer, api, currentHouseholdId}) {

  const status = useRef(null);

  const POST_STATUS_DESCRIPTION = async(status) => { 
    const response = await fetch(`${api}/api/household/status?household_id=${currentHouseholdId}`, { 
      method: "POST",
      body : JSON.stringify({ 'status_description': status}),
      headers : { 
        'Content-Type': 'application/json',
      }
    })
   return response;
  }

  const CHANGE_STATUS_BTN = async() => { 
    if(status.current.value === "Quoted" || status.current.value === "Quoted Hot" || status.current.value === "Price is too High" ){
        const response = await POST_STATUS_DESCRIPTION(status.current.value);
        if(!response.ok){ 
          alert('Error Changing Status!');
        }else { 
          setCurrentPopup(5);
        }
      
  }else { 
    const response = await POST_STATUS_DESCRIPTION(status.current.value);
        if(!response.ok){ 
          alert('Error Changing Status!');
        }else { 
          window.location.reload();
        }

  }
} 
  return (
    <>
    <div className='background-color-full-dark border-radius-10px popup'  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)} >❌</button></div>
            <div className='flex-container-el padding-20px'> 
                <h3 className='text-align-left'>Change Status Description</h3>
                <label for = "firstname" className='background-color-full-dark text-align-left margin-top-5px  '>Select status description: </label>
                
                <select className='background-color-full-dark regularTextInput margin-top-5px new-household-input max-width' autocomplete="off" ref = {status}>
                    { householdData && (householdData.status === 'Current Customer') ? currentCustomer.map(options => ( 
                    <option value = {options}>{options}</option>
                    ))
                    : 
                    householdData && (householdData.status === "Prospect") ?  prospect.map( options => ( 
                    <option value = {options}>{options}</option>
                    )) 
                    : 
                    householdData && (householdData.status === "Former customer") ?  formerCustomer.map( options => ( 
                    <option value = {options}>{options}</option>
                    ))
                    : null
                    }
                </select>
    
          <button type = "button" class = "button-default green-submit-btn margin-top-20px max-width" onClick={() => CHANGE_STATUS_BTN()} >Change status descriptions</button>
        </div>
       </div>

    
    </>
  )
}

