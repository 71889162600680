import { configureStore} from '@reduxjs/toolkit'; 
import firstReducer from '../redux/firstSlice'
import tileDisplayIndex from './tileDisplayIndex';
import toggleSettings from './toggleSettings';
export const store = configureStore({ 
    reducer: {
        firstSlice: firstReducer, 
        tileDisplayIndex: tileDisplayIndex, 
        toggleSettings:toggleSettings
     }, 
})