import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Navbar from './Navbar';
import Leftside from './leftPanel.tsx';
import MiddleSide from './Middle';
import RightSide from './rightSide';
import Searchleads from './Searchleads';
import SearchResults from './SearchResults';
import ChartTime from './chartTime.tsx';
import ChatBox from './chatBox'
import Tech_support from './Tech_support';
import UserManagement from './userManagement';
import Resources from './Resources';
import Calendar from './Calendar_page';
import Dashboard from './Dashboard';
import Sidebar from './Sidebar.tsx';
import Add_story from './leads_page_popups/Add_story';
import ManagementContainer from '../src/ManagementPage.js';
import { useSelector, useDispatch} from 'react-redux'; 
import {logoutTimeout} from '../src/logoutTimeout/LogoutTimeout.js'


let api = require('./apiConfig');
const { fetchUnassignedLeadsNew } = require('../src/fetches/fetch.js')
api = api.api;
 
function LeadsPage() {
  const [recentCustomers, setRecentCustomers] = useState([])
  const[gg, setGG] = useState();
  const jwtToken = Cookies.get('jwt');
  const [allUserData, setAllUserData] = useState();
  const [searchTerm, setSearchTerm] = useState(null);
  const [switchNumber, setSwitchNumber] = useState(7);
  const [currentHouseholdId, setCurrentHouseholdId] = useState(Cookies.get('currentHouseholdId'));
  const [currentUserData, setCurrentUserData] = useState();
  const [userIdAndFullName, setUserIdAndFullName] = useState({});
  const [tasks, setTasks] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null)
  const [ toggleAddAnnouncement, setToggleAddAnnouncement] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recentUwActions, setRecentUwActions] = useState();
  const [ newLeads, setNewLeads] = useState();
  const toggleSB = useSelector((state) => state.firstSlice.toggle)

  
  const getUwActions = async() => { 
    const response = await fetch(`${api}/api/uw_action_request?userid=${Cookies.get('userid')}`, { 
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `${Cookies.get('jwt')}`
      }
    });
    const data = await response.json();
    setRecentUwActions(data);
  }

  useEffect(() => {
    logoutTimeout();
    getUwActions();
    if(localStorage.getItem('recentCustomers')   ){ 
      setRecentCustomers(JSON.parse(localStorage.getItem('recentCustomers')))
    }


    fetch(`${api}/api/household?id=${currentHouseholdId }&user_id=${Cookies.get('userid')}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${jwtToken}`
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      let currentHouseholdId = Cookies.get('currentHouseholdId') || data[0].household_id;
      Cookies.set('currentHouseholdId', currentHouseholdId); // Store current household ID in a cookie
    })
    .catch(error => {
      // Handle any errors
      console.error('There was a problem with your fetch operation:', error);
    });

    getCurrentUserData(Cookies.get('userid'));
    fetchUserData();
   
    
  }, [jwtToken]);




    useEffect(() => { 
      async function fn () { 
        const response = await fetchUnassignedLeadsNew();

        const data = await response.json();
       

        if(data && data[0]?.user_id === null){ 
          setNewLeads(true);
        }else { 
          setNewLeads(false);
        }
        

      }
      const intervalId = setInterval(() => {
        fn(); // Call the async function inside the interval
      }, 10000);
    
      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }, [])

 
  const getCurrentUserData = async(id) => { 
    const response = await fetch(`${api}/api/user?user_id=${id}`);
    const data = await response.json();

    setCurrentUserData(data);
   

  }
  const fetchUserData = async() => { 
    const response = await fetch(`${api}/api/users/all`, { 
      headers: { 
        Authorization : jwtToken
      }
    })
    const data = await response.json()
    let tempObj = {};
  
    setAllUserData(data);

    data.forEach(user => { 
      tempObj[user.id] = user.full_name
    })
  
  setUserIdAndFullName(tempObj);
   

  }

  const getTasks = async() => { 
    const response = await fetch(`${api}/api/task?user_id=${Cookies.get('userid')}`);
    if(response.status === 200){ 
      const data = await response.json();
      setTasks(data);
    }

  }


  const updateCurrentHouseholdId = (newHouseholdId) => {
    if (newHouseholdId >= 1) {
      Cookies.set('currentHouseholdId', newHouseholdId); // Update the current household ID in the cookie
      window.location.reload();
    } else {
      newHouseholdId = 1;
      Cookies.set('currentHouseholdId', newHouseholdId);
    }
  };

  const switchMiddle = [
    <MiddleSide   
      currentUserData = {currentUserData}
      getTasks = {getTasks}
      allUserData = {allUserData}
      updateCurrentHouseholdId={updateCurrentHouseholdId}
      setCurrentHouseholdId ={setCurrentHouseholdId} 
      currentHouseholdId = {currentHouseholdId}
      api={api} 
      userIdAndFullName = {userIdAndFullName}
      setRecentCustomers = {setRecentCustomers}
      recentCustomers = {recentCustomers}
      setSwitchNumber = {setSwitchNumber}
      getUwActions = {getUwActions}

    />,
    <Searchleads
      allUserData = {allUserData}
      api={api}
      updateCurrentHouseholdId={updateCurrentHouseholdId} 
      searchTerm={searchTerm} 
      setSearchTerm={setSearchTerm} 
      setSwitchNumber={setSwitchNumber}
      setCurrentHouseholdId ={setCurrentHouseholdId}
      currentUserData={currentUserData}
      userIdAndFullName = {userIdAndFullName}
      setCurrentPopup ={setCurrentPopup}
      currentPopup={currentPopup}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
     
      
    />,
    <ChartTime 
      
      updateCurrentHouseholdId={updateCurrentHouseholdId} 
      api={api} 
      searchTerm={searchTerm} 
      setSearchTerm={setSearchTerm} 
      setCurrentHouseholdId = {setCurrentHouseholdId}
      currentUserData={currentUserData}
      setSwitchNumber={setSwitchNumber}
      
    />,

    <UserManagement />, 

    <Tech_support   
      api={api}
      currentUserData={currentUserData}
    
    />, 
    <Resources    
    api={api}
    currentUserData={currentUserData}
     /> , 
     <Calendar
      tasks= {tasks} 
      getTasks ={getTasks}
      api={api} />, 

      < Dashboard  api={api}  
      currentUserData={currentUserData}
      userIdAndFullName = {userIdAndFullName}
      setSwitchNumber={setSwitchNumber} 
      setCurrentHouseholdId = {setCurrentHouseholdId}
      />,
      <ManagementContainer 
      userIdAndFullName = {userIdAndFullName}
      currentUserData={currentUserData}
      allUserData={allUserData}
      fetchUserData ={fetchUserData}
      />
    
  ];

  return (
    
    <>
  
  
    <Navbar  setSwitchNumber={setSwitchNumber}
      allUserData={allUserData}
      userIdAndFullName = {userIdAndFullName} 
      setCurrentPopup ={setCurrentPopup}
     />
        
      <div className="holy-grail ">
      {toggleSB && 
      <div className = "sidebar ">
        
         <Sidebar
            currentUserData = {currentUserData}
            setSwitchNumber={setSwitchNumber}
            setToggleAddAnnouncement = {setToggleAddAnnouncement}
            newLeads = {newLeads}
          />
       
        </div>
         }
       
        <div className="left">
      
          <Leftside 
          tasks = {tasks}
           getTasks = {getTasks}
            setCurrentHouseholdId = {setCurrentHouseholdId}
            searchTerm={searchTerm} 
            setSearchTerm={setSearchTerm} 
            recentCustomers = {recentCustomers}
           
            
            setSwitchNumber={setSwitchNumber}
          />
        </div>
        <div className="middle flex-basis-74vw">
          {switchMiddle[switchNumber]}
        </div>
        
        <div className="right">
          <RightSide recentUwActions = {recentUwActions} updateCurrentHouseholdId={updateCurrentHouseholdId} api = {api} setSwitchNumber = {setSwitchNumber} setCurrentHouseholdId = {setCurrentHouseholdId}/>
        </div>
        
      </div>
      { toggleAddAnnouncement && <Add_story setToggleAddAnnouncement={ setToggleAddAnnouncement} setSwitchNumber={setSwitchNumber} setCurrentHouseholdId = {setCurrentHouseholdId}/> }
      
    </>
  );
}

export default LeadsPage;
