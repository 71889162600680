import {React, useEffect, useState} from 'react'
import Cookies from 'js-cookie';
import EditDocumentPopup from './documents_popups/EditDocumentPopup';
import DeletedocumentPopup from './documents_popups/DeletedocumentPopup';


function Documents({togglePopUpById, api, documentData,  fetchDocs, currentUserData, setCurrentPopup, policies }) {
    const [toggleDoc, setToggleDoc] = useState(null);
    const [currentDoc, setCurrentDoc] = useState();
    useEffect(() => {
       
       fetchDocs();
       
    }, []); 

   
    const handleFilter = async(doc_type) => { 
      await fetchDocs(doc_type);
    }
    

    const downloadFile = async (blobName) => {
        try {
          const response = await fetch(`${api}/api/download_documents`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ blobName }),
          });
      
          if (!response.ok) {
            throw new Error('Failed to download file');
          }
      
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = blobName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading file:', error);
        }
    }
    const arr = [
      <EditDocumentPopup currentDoc = {currentDoc} api = {api} fetchDocs= {fetchDocs} setToggleDoc={setToggleDoc} policies = {policies} />, 
      <DeletedocumentPopup api = {api}  documentData = {documentData} setToggleDoc={setToggleDoc} fetchDocs = {fetchDocs} currentDoc = {currentDoc}  />
    ]

  return (
    <>
    {arr[toggleDoc]}
   {console.log(policies)}
    <div className='overflow-wrapper'>
    <table className='max-width margin-top-20px ' id ="documents_table">
            <thead>
                <tr >
                    <th>Policy </th>
                    <th>
                      <select className='bg-color-black-1' onChange = {(e) => handleFilter(e.target.value)}>
                        <option className='bg-color-black-1' value = "" >All Document types</option>
                        <option className='bg-color-black-1' value = 'Additional Docs'>Additional Docs</option>
                        <option className='bg-color-black-1' value = 'Endorsements'>Endorsements</option>
                        <option className='bg-color-black-1' value = 'Proof Of Homeownership'>Proof Of Homeownership</option>
                        <option className='bg-color-black-1' value = 'Drivers License'>Drivers License</option>
                        <option className='bg-color-black-1' value = 'FL DL Check'>FL DL Check</option>
                        <option className='bg-color-black-1' value = 'New Business'>New Business</option>
                      </select>
                    </th>
                    <th>File Name</th>
                    <th>Uploaded</th>
                    <th>Action</th>
                </tr>
            </thead>
    
            <tbody   id = "document_tab_table_body" > 
            
    {documentData ? 
    documentData.map(doc => ( 
                <tr key ={doc.uw_action_documents_id}>
                <td > 
                {policies && policies.find(policy => policy.policy_id === doc.policy_number)?.policy_id_two ? policies.find(policy => policy.policy_id === doc.policy_number)?.policy_id_two : null }
                </td>
                <td > 
                {doc.document_type}
                </td>
                <td  className = 'cursor-pointer word-wrap' onClick={() => downloadFile(doc.document_name)}> 
                {doc.file_name}
                </td>
                <td> 
              
           
                {new Date(doc.created_on).toLocaleDateString('en-US')}
                </td>
                <td> 
                <button type = "button" className='button-default-dark-background' onClick={() =>{ setToggleDoc(0); setCurrentDoc(doc)}} >✍️</button><button type = "button" className='button-default-dark-background' onClick={() => window.open('mailto:name@gmail.com')}>📧</button>{currentUserData && (currentUserData[0]?.role === "Admin" ||currentUserData[0]?.role === "Supervisor" ? <button type = "button" className='button-default-dark-background' onClick={() => {setToggleDoc(1); setCurrentDoc(doc.document_name)}}>🗑️</button> : null )}
                </td>
                </tr>
            )): null}      

              
            </tbody>
           
        
            </table>
         
            </div>


  
      


    </>
  )
}

export default Documents
