import {React, useStatem, useEffect} from 'react'

function Public_record_links() {
  return (
    <div>
        <h3 className='secondary-color'>Public Record Links</h3> 
        <p className='secondary-color '>Links to public records resources</p>
   
        <div className='flex-column-no-ho-centered'>
            <h3 className='secondary-color'> Florida</h3>
            <a  className='secondary-color display-block' href='https://services.flhsmv.gov/MVCheckWeb/'>Florida VIN Check</a>
            <a  className='secondary-color display-block' href='https://services.flhsmv.gov/dlcheck/'>Florida Drivers License Check</a>
         </div>
    </div>
  )
}

export default Public_record_links
