import React from 'react'

function About() {
  return (
    <div>
        <h3 className='secondary-color'>About</h3> 
        <p className='secondary-color'>About us content goes here</p>
    </div>
  )
}

export default About
