import React, {useRef} from 'react'

function AddHomeCoverage({setCurrentCoveragePopup, sendCoverageData, policies, fetchCoverages}) {


    const belong_to_policy = useRef();
    const dwelling_erc = useRef();
    const all_other_perils_deductible = useRef();
    const liability_limit = useRef(); 
    const annual_hurricane_ded = useRef();
    const personal_property = useRef();
    const personal_property_rc = useRef();
    const screened_enclosure_carport_limit = useRef();


    const CREATE_HOME_COV_OBJ = () => { 
        if(belong_to_policy.current.value  ){ 
            let HOME_COV_OBJ = {
                type: "home",
                belong_to_policy: belong_to_policy.current.value,
                dwelling_erc: dwelling_erc.current.value,
                all_other_perils_deductible: all_other_perils_deductible.current.value,
                liability_limit: liability_limit.current.value,
                annual_hurricane_ded: annual_hurricane_ded.current.value,
                personal_property: personal_property.current.value,
                personal_property_rc: personal_property_rc.current.value,
                screened_enclosure_carport_limit: screened_enclosure_carport_limit.current.value
            } 

            return HOME_COV_OBJ;

        }else { 
            return alert('Missing Policy Number or VIN')
        }

    }

    const CREATE_NEW_HOME_COV_BTN = async() => { 
        const obj = CREATE_HOME_COV_OBJ();
        
        const response = await sendCoverageData(obj); 
        if(response.status === 500 ){ 
            return alert('Error Creating New Coverage! Check connection!')
        }else { 
            fetchCoverages('home', belong_to_policy.current.value);
            setCurrentCoveragePopup(null)
        }
    }

  return (
    <div className='background-color-full-dark border-radius-10px popup' >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentCoveragePopup(null)} >❌</button></div>
            <div className='flex-container-el padding-20px'> 
            <h3 className='text-align-left margin-bottom-10'>Add Home Coverage </h3>
            <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px '>Policy Number</label>
            <select className='background-color-full-dark regularTextInput margin-top-5px max-width' ref={belong_to_policy}>
            {policies && policies.map(policy => (
                <option values = {policy.policy_id}>{policy.policy_id}</option>
            )
            )}
            </select>
            <label className='background-color-full-dark text-align-left margin-top-5px margin-top-5px'>Dwelling ERC</label>
            <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' autocomplete="off" ref={dwelling_erc}/>
            <label  className='background-color-full-dark text-align-left margin-top-5px margin-top-5px '>All other perils deductible</label>
            <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width'  autocomplete="off" ref={all_other_perils_deductible}/>
            <label  className='background-color-full-dark text-align-left margin-top-5px margin-top-5px'>Liablity Limit</label>
            <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width'  autocomplete="off" ref={liability_limit}/>
            <label  className='background-color-full-dark text-align-left margin-top-5px margin-top-5px'>Annual Hurricane Ded</label>
            <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width'  autocomplete="off" ref={annual_hurricane_ded}/>
            <label  className='background-color-full-dark text-align-left margin-top-5px margin-top-5px'>Personal Property</label>
            <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width'  autocomplete="off" ref={personal_property}/>
            <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-10 '>Personal Property RC</label>
            <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width  ' autocomplete="off" ref={personal_property_rc}/>
            <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-10px '>Screened enclosure carport limit</label>
            <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width '  autocomplete="off" ref={screened_enclosure_carport_limit}/>
            <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width " id = "add=driver-submit-btn" onClick={() =>CREATE_NEW_HOME_COV_BTN()} >Add new coverage</button>
            </div>
  </div>
  )
}

export default AddHomeCoverage
