import React, {useState, useRef} from "react";
const { changeChildStatus, changeParentStatus} = require('../fetches/fetch.js')
function ChangeSubStatus({   setChangeSubStatus, fetchData, currentPage, currentHouseholdID, toggleDisplay, searchTerm,   toDate, fromDate}) {
  const childStatus = useRef();
  const parentStatus = useRef();


  const statuses = [
    "New",
    "Quoted",
    "Quoted Hot",
    "Called, No Contact",
    "Called, LVM",
    "BAD NUMBER",
    "Do Not Call",
    "Not Elegible",
    "Price is too High",
    "Contacted  Quoted & Call Back",
    "Not Interested",
    "Cancelled",
    "Terminated",
    "Try again in 6 months",
    "Closed",
    "Policy In Force",
    "Already With Allstate",
    "Follow Up/Ready to Close",
    "Followed Up",
  ];

  

  const changeStatus = async() => { 
   
    try{ 
        await changeChildStatus(currentHouseholdID, childStatus.current.value);
        await changeParentStatus(parentStatus.current.value, currentHouseholdID);
        await fetchData(currentPage, searchTerm, toDate, fromDate);
        setChangeSubStatus(false);
    }catch(error){ 
        return alert('Error changing status')

    }   
}


  const handleSubmit = async(e) => { 
    
    return await changeStatus()

  }

  return (
    <div className="background-color-full-dark border-radius-10px  popup z-index ">
      <div className="black-background  text-align-right border-radius-top-10px ">
        <button
          type="button"
          className="popup-exit-button"
          onClick={() => toggleDisplay(false)}
        >
          ❌
        </button>
      </div>
      <div className="flex-container-el padding-10px">
        <h3 className="padding-10px">Change Status</h3>
        <select className= "background-color-full-dark padding-10px no-border mediumtext button-hover max-width" ref = {parentStatus}> 
            <option value = 'Prospect'>Prospect</option>
            <option value = 'Current Customer'>Current Customer</option>
            <option value = 'Former Customer'>Former Customer</option>
        </select>
        <select className= "background-color-full-dark padding-10px no-border mediumtext button-hover max-width" ref = {childStatus}> 
          {statuses &&
            statuses.map((status) => <option value={status}>{status}</option>)}
        </select>
        <button className="light-dark-background button-default green-submit-btn margin-top-10px " onClick = {() => handleSubmit()}> 
            Submit
        </button>
      </div>
    </div>
  );
}

export default ChangeSubStatus;
