import React, {useState, useEffect} from 'react'
import DateRangePicker from '../src/DatePicker'
import Cookies from 'js-cookie'
import Payroll from './Payroll'
const {get_time_punch} = require('../src/fetches/fetch') 



export function TimeTable({timePunches, currentUserData,  setTotalHours, setEstimatedPay} ){ 

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(() => {
    if (currentUserData && timePunches) {
      let hoursSum = 0;
      let paySum = 0;

      timePunches.forEach((timePunch) => {
        const firstPunch = new Date(timePunch.first_punch);
        const lastPunch = new Date(timePunch.last_punch);

        const hoursWorked = lastPunch.getHours() - firstPunch.getHours();
        const minutesWorked = lastPunch.getMinutes() - firstPunch.getMinutes();

        let adjustedHours = hoursWorked;
        let adjustedMinutes = minutesWorked;

        if (minutesWorked < 0) {
          adjustedHours -= 1; // Subtract one hour
          adjustedMinutes += 60; // Convert negative minutes to positive
        }
        
        // Add fractional hours
        adjustedHours += adjustedMinutes / 60;

        hoursSum += adjustedHours;
        paySum += adjustedHours * currentUserData[0].hourly_rate;
      });

      setTotalHours(hoursSum);
      setEstimatedPay(paySum);
    }
  }, [currentUserData, timePunches]);

  return ( 
  <> 
  <table className='max-width'>
    <thead >
      <th>Date</th>
      <th>First Clock In</th>
      <th>Final Clock Out</th>
      <th>Hours</th>
      <th>Pay</th>
    </thead>
    <tbody   id="search-results_table_body">
      { currentUserData && timePunches && timePunches.map(timePunch => ( 
       <>
     
        <tr>
          <td> {daysOfWeek[new Date(timePunch.first_punch).getDay()]}<br/>{new Date(timePunch.first_punch).toLocaleDateString()}</td>
          <td>{new Date(timePunch.first_punch).toLocaleTimeString()}</td>
          <td>{new Date(timePunch.last_punch).toLocaleTimeString()}</td>
           <td>{timePunch.hours + "h " + timePunch.minutes + "m "}</td>
           <td>
            {(() => {
              const firstPunch = new Date(timePunch.first_punch);
              const lastPunch = new Date(timePunch.last_punch);

              const hoursWorked = lastPunch.getHours() - firstPunch.getHours();
              const minutesWorked = lastPunch.getMinutes() - firstPunch.getMinutes();
              
              // Adjust if minutesWorked is negative
              const totalHours = hoursWorked + (minutesWorked / 60);
              
              // Multiply the total hours by the hourly rate
              const totalPay = currentUserData[0].hourly_rate * totalHours;
         
            
              return `$${totalPay.toFixed(2)}`; // Show the result as a formatted currency
            })()}
            </td>
        </tr>
       </>

      ))}
    
   
     
    </tbody>
  </table>

  </>);
}

export function ManagementNavigation({currentUserData, currentManagementPage, setCurrentManagementPage}) { 
  return (
    <>
      <div className='light-dark-background flex margin-bottom-10'>
      
        <div 
          className={`light-dark-background padding-10px cursor-pointer ${currentManagementPage === 0 ? 'management-nav' : null } `}
          onClick={() => setCurrentManagementPage(0)}>
            Timsheet
        </div>
        {currentUserData && currentUserData[0].role === 'Admin' ? ( 
        <div 
          className={`light-dark-background padding-10px cursor-pointer ${currentManagementPage === 1 ? 'management-nav' : null } `}
          onClick={() => setCurrentManagementPage(1)} >
            Payroll
        </div>
        ) : null }
        <div 
          className={`light-dark-background padding-10px cursor-pointer ${currentManagementPage === '...' ? 'management-nav' : null } `}
          onClick={() => setCurrentManagementPage('...')}>
            ...
        </div>


    
      </div>
    </>)
}

export function ManagementPage({currentUserData, userIdAndFullName, allUserData, fetchUserData }) {


  const [currentManagementPage, setCurrentManagementPage] = useState();
  const [timePunches, setTimePunches] = useState();
  const [user, setUser] = useState(Cookies.get('userid'));
  const [startDate, setStartDate] = useState(getFirstDateOfWeek().toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [orderedTimePunches, setOrderedTimePunches] = useState(0);
  const [ estimatedPay, setEstimatedPay ] = useState(0);
  const [ totalHours, setTotalHours] = useState();
  useEffect(() => { 
    returnTimePunches();
  }, [startDate, endDate, user])
 
  

  function getFirstDateOfWeek() {
    const now = new Date(); // Current date
    const dayOfWeek = now.getDay(); // Day of the week (0 for Sunday, 1 for Monday, etc.)
    const diff = now.getDate() - dayOfWeek; // Subtract the day of the week to get the previous Sunday
    return new Date(now.setDate(diff)); // Return the date object for the start of the week
  }

  const returnTimePunches = async() => { 
    await fetchUserData();
    const startOfCurrentWeek = getFirstDateOfWeek().toISOString();
    const response = await get_time_punch(startDate, endDate, user);
    const time_punches = await response.json();
    setTimePunches(time_punches);

    
    
  }

  


  return (
    <div >
     
        <div className=' flex-across align-items-center space-between margin-bottom-10'>
          <h3 className = 'margin-left-10 '>
            {currentUserData && currentUserData[0].role === 'Admin' ? ( 
                <select onChange = {(e) => {setUser(e.target.value) }}> 
                {Object.keys(userIdAndFullName).map(key => ( 
                  <option value = {key}>{userIdAndFullName[key]}</option>
                ))}
              </select>
            ) : currentUserData[0].role }
          's Timesheet</h3>
          
          <DateRangePicker setStartDate= { setStartDate} 
          setEndDate= {setEndDate}
          getFirstDateOfWeek = {getFirstDateOfWeek}
          startDate = {startDate}
          endDate = {endDate}
          />
        </div>
        <div className='grid-management margin-bottom-10'> 
          <div className='flex-column-no-ho-centered light-dark-background'><span className='light-dark-background padding-10px'>Total Hours<h2 className='light-dark-background '>{totalHours ? totalHours && totalHours.toFixed(2): "N/A"}</h2></span></div>
          <div className='flex-column-no-ho-centered light-dark-background'><span className='light-dark-background padding-10px'>Estimated Pay<h2 className='light-dark-background '>${estimatedPay ? estimatedPay.toFixed(2) : "N/A"}</h2></span></div>
          <div className='flex-column-no-ho-centered light-dark-background'><span className='light-dark-background padding-10px'>Hourly Pay<h2 className='light-dark-background '>${allUserData ? allUserData.filter(obj => obj.id === user)[0].hourly_rate : "N/A"}</h2></span></div>
        </div>
        <TimeTable 
         setTotalHours={ setTotalHours}
        setEstimatedPay ={setEstimatedPay}
        timePunches= {timePunches} 
        currentUserData= {currentUserData}
       
        />
     
    </div>

  )
}

/*Loop through the time punches and figure out the day of the current being looped over and group all timestamps by day*/






export default function ManagementContainer({userIdAndFullName, currentUserData, allUserData, fetchUserData}) {
  
  const [currentManagementPage, setCurrentManagementPage ] = useState(0);
  const pages = [ 
  
    <ManagementPage
        userIdAndFullName = {userIdAndFullName}
        currentUserData={currentUserData}
        allUserData={allUserData}
        fetchUserData ={fetchUserData}
      />, 
      <Payroll 
        userIdAndFullName = {userIdAndFullName}
        allUserData={allUserData}
        
      />
  ]
  return (
    <div>      
      <ManagementNavigation 
      setCurrentManagementPage ={setCurrentManagementPage}
      currentManagementPage = { currentManagementPage}
      currentUserData={currentUserData}
      />
      {pages[currentManagementPage]}
    </div>
  )
}

