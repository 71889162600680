import React, {useRef} from 'react'

function Delete_lead({setCurrentPopup, checkedHouseholds, api, fetchData, currentPage, toDate, fromDate, searchTerm}) {
    const delete_leads = async() => { 

        const arryOfHouseholdIds = [...checkedHouseholds];

        const response = await fetch(`${api}/api/household`, { 
            method: "DELETE", 
            headers: { 
                "Content-Type": "application/json"
            }, 
            body: JSON.stringify({
                household_ids: arryOfHouseholdIds
            })
        })
        return response;
    }

    const remove_btn = async() => { 
        if(checkedHouseholds.size === 0)return alert("Must select a household to delete!");
        const response = await delete_leads();

        if(response.status === 200){ 
        await fetchData(currentPage, searchTerm, toDate, fromDate);
        return setCurrentPopup(null);
        }

        return alert('Error deleting leads!');

    }

  return (
    <>
    <div className='background-color-full-dark border-radius-10px  popup z-index'  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick= { () => setCurrentPopup(null)}>❌</button></div>
            <div className='flex-container-el'> 
            <h3 className='padding-10px'>Warning! Please confirm!</h3>
            
            <button type = "button" className='button-default red-submit-btn margin-top-20px max-width padding-10px' onClick = {() => remove_btn()} >Delete </button>
            <button type = "button" className='button-default green-submit-btn margin-top-20px max-width padding-10px' onClick = {() =>  setCurrentPopup(null)} >Cancel </button>
            
        </div>
    </div>
    </>
  )
}

export default Delete_lead
