
import Cookies from 'js-cookie';
const {postTimePunch} = require('../fetches/fetch')
export const logoutTimeout = () => {
    let timer; // Variable to store the timer ID
    
    const resetTimer = () => {
        clearInterval(timer); // Clear the previous timer
        startTimer(); // Restart the timer
  
    };

    const startTimer = () => {
        // Set a timer that runs every 10 seconds
        timer = setTimeout(() => {
         
            logout(); // Call your function after the timer completes
        },   600000 ); // 10 minutes
       
    };

    // Function to execute when the timer completes
    const logout = async() => {
        await postTimePunch(Cookies.get('userid'), new Date, 'logout')
        document.removeEventListener("mousemove", resetTimer);
        Cookies.remove('jwt')
        window.location.reload();
    };

    // Start the timer when the function is first called
    startTimer();

    // Listen for mouse movements to reset the timer
    document.addEventListener("mousemove", resetTimer);
};
