import React, {useEffect, useRef, useState} from 'react'



function Change_Branch({setCurrentPopup, api, checkedHouseholds}) {
  const [branches, setBranches] = useState(null);
  const branch_id = useRef(null);

  useEffect( ()=> {
    const fetchData = async() => { 
      await getBranches();
    }
    fetchData();
    console.log(branches)

  }, [])

  const getBranches = async() => { 

    await fetch(`${api}/api/branches`)
    .then(async response => {
      let data = await response.json()
      setBranches(data);

    })
    .catch(error =>{ 
      alert('Error fetching branches');

    })
  
  }

  const postBranches = async() => { 
   let arr = [...checkedHouseholds].join(); //convert from set to arry to string.
    const response = await fetch(`${api}/api/household/branch`,{ 
      method: "PUT", 
      headers: { 
        'Content-Type': 'application/json'
      }, 
      body: JSON.stringify({
        household_ids: arr, 
        branch_id: branch_id.current.value
      })
    })
    return response;
  }

  const changeBranchBtn = async() => { 
    if(checkedHouseholds.size === 0) return alert("Must select households!");
    const response = await postBranches(); 
    if(response.status === 500){ 
      return alert("Error changing branches");
    }
    setCurrentPopup(null)
  } 


  return (

    <div className='background-color-full-dark border-radius-10px  popup z-index '  >
      <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)}>❌</button></div>
          <div className='flex-container-el padding-10px ' > 
              <h3 className='padding-10px'>Change Branch </h3>
                      <div className='flex-column-no-ho-centered'>
                        <select className='margin-top-10px green-submit-btn  ' ref ={branch_id}>
                          {branches && branches.map(branch => (
                            <option value = {branch.branch_id}>{branch.branch_name}</option>))}
                        </select>
                      </div>
                </div>
          <button type = "button" className='button-default green-submit-btn margin-top-20px max-width padding-10px' onClick = {() => changeBranchBtn()} >Change Branch</button>
     </div>

  )
}

export default Change_Branch
