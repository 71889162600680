import React from 'react'

function forms() {
  return (
    <div>
        <h3 className='secondary-color'>Forms</h3> 
        <p className='secondary-color'>Access important forms</p>
    </div>
  )
}

export default forms
