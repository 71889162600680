import io from "socket.io-client";
import Cookies from "js-cookie";

let user_id = Cookies.get('userid');
let socket;
const socket_init = () => { 
if (!socket) {
    socket = io("https://webpupsubforsocketio.webpubsub.azure.com", {
      path: "/clients/socketio/hubs/Hub",
    });
    
    socket.on('connect', () => {
      socket.emit('userConnected', user_id);
    });
  }
};

export {
    socket_init,
    socket
 } 