import React from 'react'

function Uw_guidelines() {
  return (
    <div>
    <h3 className='secondary-color'>UW Guidelines </h3> 
    <p className='secondary-color'>Guidelines and policies from the University of Washington.</p>
    </div>
  )
}

export default Uw_guidelines
