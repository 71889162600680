import React from 'react';
import {useEffect, useState, useContext} from 'react';


function Coverages({  fetchedCoverages, jwtToken, api, setCurrentCoveragePopup   }) {

  const [policyData, setPolicyData] = useState();

  useEffect(() => {
    if (fetchedCoverages && fetchedCoverages.length > 0) {
      fetch(`${api}/api/policy?id=${fetchedCoverages[0].belong_to_policy}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${jwtToken}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setPolicyData(data)
      })
      .catch(error => {
        // Handle any errors
        console.error('There was a problem with your fetch operation:', error);
      });
    }
  }, [fetchedCoverages, api, jwtToken]);

  if (!Array.isArray(fetchedCoverages)) {
    return null;
  }

  return (
    <> 
    
      {fetchedCoverages[0]?.vin && policyData &&  (
 
        <>
             
          <h3 className='text-align-left color-orange'>Address:  {policyData ?  policyData.address_line_2 : " "}</h3>
          <table className='max-width margin-top-10px' id="policy-info">
            <thead>
              <tr>
                <th>Liability</th>
                <th>BI</th>
                <th>PD</th>
                <th>PIP</th>
                <th>Guest Liability</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Limits</td>
                <td>{policyData.bi_liability_limits || "N/A"}</td>
                <td>{policyData.pd_liability_limits || "N/A"}</td>
                <td>{policyData.pip_liability_limits || "N/A"}</td>
                <td>{policyData.guest_liability || "N/A"}</td>
              </tr>
              <tr>
                <td>Deductibles</td>
                <td>{policyData.bi_liability_deductibles || "N/A"}</td>
                <td>{policyData.pd_liability_deductibles || "N/A"}</td>
                <td>{policyData.pip_liability_deductibles || "N/A"}</td>
                <td>{policyData.guest_liability_deductibles || "N/A"}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      {fetchedCoverages.map((coverage, index) => (
        coverage.vin ? (
          <table key={index} className='max-width margin-top-20px' id="policy-info">
            <thead>
              <tr>
                <th>Vehicle VIN:<br />{coverage.vin}</th>
                <th>Comprehensive</th>
                <th>Collision</th>
                <th>Rental</th>
                <th>Roadside</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Limits</td>
                <td>{coverage.comp_limits || 'N/A'}</td>
                <td>{coverage.collision_limits || 'N/A'}</td>
                <td>{coverage.rental_limits || 'N/A'}</td>
                <td>{coverage.roadside_limits || 'N/A'}</td>
              </tr>
              <tr>
                <td>Deductibles</td>
                <td>{coverage.comp_deductibles || 'N/A'}</td>
                <td>{coverage.collision_deductibles || 'N/A'}</td>
                <td>{coverage.rental_deductibles || 'N/A'}</td>
                <td>{coverage.roadside_deductibles || 'N/A'}</td>
              </tr>
            </tbody>
          </table>
        ) : null
      ))}


{fetchedCoverages[0]?.items && policyData && (
        <>
          <h3 className='text-align-left color-orange'>Address:  {policyData ?  policyData.address_line_2: ""}</h3>
         
        </>
      )}

{fetchedCoverages[0]?.dwelling_erc && policyData && (
        <>
          <h3 className='text-align-left color-orange'> Coverage Address:  {policyData ?  policyData.address_line_2 : ""}</h3>
         
        </>
      )}


{fetchedCoverages.map((coverage, index) => (
        coverage.items ? (
          <table key={index} className='max-width margin-top-5px dark-border-bottom' id="policy-info">
            
            <tbody className='border-none'>
              <tr>
                <td className='border-none'>Items:</td>
                <td className='border-none'>{coverage.items}</td>
               
              </tr>
              <tr>
                <td className='border-none'>Limits:</td>
                <td className='border-none'>{coverage.limits}</td>
              </tr>
              <tr>
                <td className='border-none'>Deductibles:</td>
                <td className='border-none'>{coverage.deductibles}</td>
               
              </tr>
              <tr>
                <td className='border-none'>Wearer:</td>
                <td className='border-none'>{coverage.wearer}</td>
              </tr>
            </tbody>
          </table>
        ) : null
      ))}


{fetchedCoverages.map((coverage, index) => (
        coverage.personal_property ? ( 
        <div className='width-400px'>
          <p className='max-width'> Personal Property </p>
          <p className='max-width' >  {coverage.personal_property} </p>
       </div>
         
        ):null
      ))}

{fetchedCoverages.map((coverage, index) => (
        coverage.excess_liability ? ( 
        <div className='width-400px'>
          
          <p className='max-width inline-block' ><h4>Excess Liability:</h4>{coverage.excess_liability} </p>
          <p className='max-width inline-block' ><h4>Uninsured Motorist:</h4>{coverage.uninsured_motorist} </p>
          <p className='max-width inline-block' ><h4>Aggregated Limit</h4>{coverage.aggregated_limit} </p>
       </div>
         
        ):null
      ))}



{fetchedCoverages.map((coverage, index) => (
        coverage.dwelling_erc ? (
          <table key={index} className='max-width margin-top-5px dark-border-bottom' id="policy-info">
            
            <tbody className='border-none'>
              <tr>
                <td className='border-none'>1MAIN: </td>
                <td className='border-none'>Limits</td>
               
              </tr>
              <tr>
                <td className='border-none'>Dwelling ERC: </td>
                <td className='border-none'>{coverage.dwelling_erc}</td>
              </tr>
              <tr>
                <td className='border-none'>All Other Perils Deductible: </td>
                <td className='border-none'>{coverage.all_other_perils_deductible}</td>
               
              </tr>
              <tr>
                <td className='border-none'>Liability Limit: </td>
                <td className='border-none'>{coverage.liability_limit}</td>
              </tr>
              <tr>
                <td className='border-none'>Annual Hurricane Ded: </td>
                <td className='border-none'>{coverage.annual_hurricane_ded}</td>
              </tr>
              <tr>
                <td className='border-none'>Annual Personal Property: </td>
                <td className='border-none'>{coverage.personal_property}</td>
              </tr>
              <tr>
                <td className='border-none'>Personal Property RC: </td>
                <td className='border-none'>{coverage.personal_property_rc}</td>
              </tr>
              <tr>
                <td className='border-none'>Screened Enclosure: </td>
                <td className='border-none'>{coverage.screened_enclosure_carport_limit}</td>
              </tr>
              <tr>
                <td className='border-none'>Wind mitigation credit form: </td>
                <td className='border-none'>YES</td>
              </tr>
            </tbody>
          </table>
        ) : null
      ))}

     


      
      <button 
        type="button" 
        className='max-width button-default margin-top-10' 
        onClick={() => setCurrentCoveragePopup(0)}
      >
        Add New Coverage
      </button>
    </>
  );
}

export default Coverages;
