import React from 'react'

function Home() {
  return (
    <div>
    <h3 className='secondary-color'>Home </h3> 
    <p className='secondary-color'>Welcome to the home page!</p>
    </div>
  )
}

export default Home;
