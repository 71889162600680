import React, { useRef} from 'react'

function Change_tags({setCurrentPopup, checkedHouseholds, api}) {
  const tag_desc = useRef(null)

  const post_tag = async()=> { 
    
    let arrOfHouseholdIds = [...checkedHouseholds]
    const response = await fetch(`${api}/api/household/tag`, { 
      method: "POST", 
      headers: { 
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        household_ids: arrOfHouseholdIds, 
        tag_desc: tag_desc.current.value

      })
    })
    return response;
  }

  const changeTags = async() => { 

    if(!tag_desc.current.value){ 
      return alert("You must specify a tag!")
    }

    if(checkedHouseholds.size === 0){ 
      return alert("You must select a household!")
    }
    const response = await post_tag(); 

    if(response.status === 200){  
       return setCurrentPopup(null)
     
    }
    return alert('error!');
  }

  return (
    <> 
    <div className='background-color-full-dark border-radius-10px  popup z-index'  >
         <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick= { () => setCurrentPopup(null)}>❌</button></div>
             <div className='flex-container-el'> 
                <h3 className='padding-10px'>Change/Add Tags</h3>
             <input type = "text" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width ' ref = {tag_desc}/>
             <button type = "button" className='button-default green-submit-btn margin-top-20px max-width padding-10px' onClick={()=> changeTags()}>Change Tags</button>
             
         </div>
     </div>
    </>
  )
}

export default Change_tags
