import React, { useRef } from "react";
const { moveToSharkTank } = require("../fetches/fetch");

function Change_ownership({
  household_id,
  setCurrentPopup,
  userIdAndFullName,
  checkedHouseholds,
  api,
  fetchData,
  currentPage,
  toDate,
  fromDate,
  searchTerm,
  setToggleOwnershipPopup
}) {
  const user_id = useRef(null);

  const postStatus = async () => {
    let response;
    if (household_id) {
      response = await fetch(`${api}/api/household/change_owner`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userID: user_id.current.value,
          checkedHouseholds: [household_id],
        }),
      });
    } else {
      const arrOfHouseholds = [...checkedHouseholds];
      response = await fetch(`${api}/api/household/change_owner`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userID: user_id.current.value,
          checkedHouseholds: arrOfHouseholds,
        }),
      });
    }
    return response;
  };

  const changeStatus = async () => {
    let response;
    if (user_id.current.value !== "shark_tank") {
      response = await postStatus();
    } else {
      let arrOfHouseholds;
      if (household_id) {
        arrOfHouseholds = [household_id];
      } else {
        arrOfHouseholds = [...checkedHouseholds];
      }

      response = await moveToSharkTank(arrOfHouseholds);
    }

    if (response.status === 200) {
      await fetchData(currentPage, searchTerm, toDate, fromDate);
      
      return setCurrentPopup(null);
      
    }
    alert("Error changing ownership!");
  };

  return (
    <>
      <div className="background-color-full-dark border-radius-10px  popup z-index">
        <div className="black-background  text-align-right border-radius-top-10px ">
          <button
            type="button"
            className="popup-exit-button"
            onClick={() => setCurrentPopup(null)}
          >
            ❌
          </button>
        </div>
        <div className="flex-container-el">
          <h3 className="padding-10px">Assign to user </h3>
          <select
            className="background-color-full-dark padding-10px no-border mediumtext button-hover max-width"
            ref={user_id}
          >
            <option value="shark_tank">Move to Shark Tank</option>
            {userIdAndFullName &&
              Object.keys(userIdAndFullName).map((user) => (
                <option value={user}>{userIdAndFullName[user]}</option>
              ))}
          </select>
          <button
            type="button"
            className="button-default green-submit-btn margin-top-20px max-width padding-10px"
            onClick={() => changeStatus()}
          >
            Change Status
          </button>
        </div>
      </div>
    </>
  );
}

export default Change_ownership;
