import {React, useRef} from 'react'

function AddMotorCoverage({sendCoverageData, setCurrentCoveragePopup, policies, fetchCoverages}) {

    const belong_to_policy = useRef();
    const vin = useRef();
    const comp_limits = useRef();
    const collision_limits = useRef();
    const rental_limits = useRef();
    const roadside_limits = useRef();
    const comp_deductibles = useRef();
    const collision_deductibles = useRef();

    const CREATE_MOTOR_COV_OBJ = () => { 
        if(belong_to_policy.current.value && vin.current.value ){ 
            let MOTOR_COV_OBJ = {
                type: "motorcycle",
                belong_to_policy: belong_to_policy.current.value,
                vin: vin.current.value,
                comp_limits: comp_limits.current.value,
                collision_limits: collision_limits.current.value,
                rental_limits: rental_limits.current.value,
                roadside_limits: roadside_limits.current.value,
                comp_deductibles: comp_deductibles.current.value,
                collision_deductibles: collision_deductibles.current.value, 
                
            } 

            return MOTOR_COV_OBJ;

        }else { 
            return alert('Missing Policy Number or VIN')
        }

    }


    const CREATE_NEW_MOTOR_COV_BTN = async() => { 
        const obj = CREATE_MOTOR_COV_OBJ();
        console.log(obj)
        const response = await sendCoverageData(obj); 
        if(response.status === 500 ){ 
            return alert('Error Creating New Coverage! Check connection!')
        }else { 
            fetchCoverages('motorcycle', belong_to_policy.current.value);
            setCurrentCoveragePopup(null);
        }
    }



  return (
    <div className='background-color-full-dark border-radius-10px popup'  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentCoveragePopup(null)} >❌</button></div>
            <div className='flex-container-el padding-20px'> 
                <h3 className='text-align-left margin-bottom-10'>Add Motorcycle Coverage </h3>
                <label for = "lastname" className='background-color-full-dark text-align-left   margin-top-5px '>Policy Number:</label>
                <select className='background-color-full-dark regularTextInput margin-top-5px  max-width ' ref = {belong_to_policy}>
                {policies && policies.map(policy => (
                    <option values = {policy.policy_id}>{policy.policy_id}</option>
                )
                )}
                </select>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>VIN Coverage</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width'  ref={vin} autocomplete="off"/>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Comp Limits</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' ref={comp_limits} autocomplete="off"/>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Collision Limits</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width' ref={collision_limits} autocomplete="off"/>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Rental Limits</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width'  ref={rental_limits} autocomplete="off"/>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Roadside Limits</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' ref={roadside_limits} autocomplete="off"/>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Comp Deuctibles</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' ref={comp_deductibles} autocomplete="off"/>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Collision Deuctibles</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' ref = {collision_deductibles} autocomplete="off"/>
                <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width "   onClick={() => CREATE_NEW_MOTOR_COV_BTN()}  >Add new coverage</button>
                </div>
      </div>
  )
}

export default AddMotorCoverage
