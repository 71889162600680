import React from 'react'; 


function DatePicker({setEndDate, setStartDate, getFirstDateOfWeek, startDate, endDate }) {
  const formattedStartDate = startDate ? new Date(startDate).toISOString().slice(0, 10) : '';
  const formattedEndDate = endDate ? new Date(endDate).toISOString().slice(0, 10) : '';


  return (
    <div className = 'flex align-items-center light-dark-background '>
   
        <b><p className='light-dark-background padding-5px'>From:</p></b>
        <input type= 'date' value={formattedStartDate} className ='padding-5px datepicker' onChange = {(e) => e.target.value ? setStartDate(e.target.value) : null } />
        <b><p className='light-dark-background' padding-5px>To:</p></b>
        <input type= 'date' className ='padding-5px datepicker' value = {formattedEndDate} onChange = {(e) => e.target.value ? setEndDate(e.target.value) : null }/>
        <b><p className='light-dark-background padding-right-10px cursor-pointer' onClick = {() => {setStartDate(getFirstDateOfWeek().toISOString()); setEndDate(new Date().toISOString())}}>Clear</p></b>
    </div>
  )
}

export default DatePicker