import { createSlice } from '@reduxjs/toolkit'

export const toggleSettings = createSlice({ 
    name: 'toggleSettings', 
    initialState: { 
        toggle:false
    }, 
    reducers: { 
        toggle:(state) => { 
            state.toggle = !state.toggle;
        }
    }
})

export const {toggle} = toggleSettings.actions; 
export default toggleSettings.reducer