import {React, useState, useEffect} from 'react'
import Cookies from 'js-cookie';
import EditUser from './user_management_popups/editUser.js';
import AddUserPopup from './user_management_popups/AddUserPopup.js';
import DeleteUser  from './user_management_popups/DeleteUser.js';
let apiConfig = require('./apiConfig.js')
let api = apiConfig.api;
const jwt = Cookies.get('jwt');





function UserManagement() {
    const [userData, setUserData] = useState();
    const [editUserPopup, setEditUserPopup] = useState(false);
    const [userToEditData, setUserToEditData] = useState();
    const [addUserPopup, setAddUserPopup ] = useState(false);
    const [toggleDelete, setToggleDelete ] = useState(false);
    const[selectedUserId, setSelectedUserId] = useState();

    useEffect(() => {  
            fetchUsers();
     },[])

     const fetchUsers = async() => { 
        const response  = await fetch(`${api}/api/users/all`, { 
            headers: { 
                Authorization : jwt 
            }
        });

        const data = await response.json();
        
        setUserData(data);
        
     }

  const togglePopUpById = (id) => { 
    document.getElementById(id).hidden = !document.getElementById(id).hidden;
  } 


  const fetchDataNewUser = async(userDataObj) => { 

    const response = await fetch(`${api}/api/users/create`, { 
        method : "POST",
        headers : { 
          'Content-Type' : "application/json",
          Authorization : jwt
        },
        body : JSON.stringify(userDataObj)
    })
    if(response.ok){ 
      fetchUsers();
    }else { 
      alert("error could not create user!");
    }


  }


 

  const deleteUser = (user_id) => { 
    
    fetch(`${api}/api/user?user_id=${user_id}`, { method: "DELETE"})
    .then(response => {
      if(!response.ok){ 
        throw new Error(`Failed to delete user. Status Code: ${response.status}`);
      }
      fetchUsers();
      setToggleDelete(false);
      return response.json();

    })
    .catch(error => console.error(error.message));
  }

  return (
    <>
    {toggleDelete && <DeleteUser setToggleDelete={setToggleDelete} deleteUser={deleteUser} selectedUserId= {selectedUserId}/> }
    {editUserPopup ?  <EditUser setEditUserPopup = {setEditUserPopup} userToEditData = {userToEditData} api = {api} fetchUsers={fetchUsers}/> : null}
    {addUserPopup ? <AddUserPopup fetchDataNewUser= {fetchDataNewUser}  togglePopUpById={togglePopUpById} setAddUserPopup = {setAddUserPopup} /> : null}
    {userData ? ( 
    <div className='search-overflow-wrapper'>
    <table className='max-width ' id="search-results">
      <thead>
        <tr>
          <th  className= "hover-cursor-pointer" onClick={() => setAddUserPopup(true)}>+ User ID</th>
          <th>Username</th>
          <th>Full Name</th>
          <th>Role</th>
          <th>Email</th>
          
        </tr>
      </thead>
      <tbody id="search-results_table_body" className=''>
      
        {userData ? userData.map(user => (
          <tr>
           <td>
           <div className='inline-el' onClick={() => {setSelectedUserId(user.id); setToggleDelete(true)}}>🗑️</div>
           <div className='inline-el' onClick={() => {setEditUserPopup(true); setUserToEditData(user) }}>🖊️</div>
            {user.id}
          </td>

          <td> 
              {user.username}
          </td>
          <td> 
              {user.full_name}
          </td>

          <td> 
              {user.role}
          </td>

          <td> 
              {user.email}
              
          </td>
       
            

          </tr>

        )): null}
        
      </tbody>
      </table>
      </div>
        
  
      ): <div className = "flex-center-column light-dark-container"><img   className = "light-dark-container ten-percent-width" src = "https://i.gifer.com/ZKZg.gif"/></div>}
    </> 
    
  )
}

export default UserManagement
