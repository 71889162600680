import React, {useRef, useState} from 'react'
import Cookies from 'js-cookie'
const {fetchQuotes, postQuotes, } = require('../fetches/fetch.js')

function AddQuotePopup({setCurrentPopup, quotes, fn}) {
    const fileUpload = useRef();
    const [fileName, setFileName] = useState();
    const note_id = useRef();
    
    const handleAttachFile = (e) => {
        fileUpload.current.click();
        
      };

    const handleUpload = async(e) => { 
        if(fileUpload.current.files[0] && note_id.current.value){ 
            const formObject = new FormData();
            const file = fileUpload.current.files[0];
            formObject.append('files', file); 
            formObject.append('household_id', Cookies.get('currentHouseholdId'));
            console.log(note_id.current.value)
            formObject.append('notes_id', note_id.current.value)
            
            const response =  await postQuotes(formObject);
            if(response.status !== 500){ 
            await fn();
            return setCurrentPopup(null);
            }
            return alert('error uploading')
        }
        return alert('No Quote or File Selected')
    }
    
    const updateFileName = (e) => { 
        setFileName(fileUpload?.current?.files[0]?.name)
    }


  return (
    <div className='background-color-full-dark border-radius-10px popup width-800px' id = "add-notes"  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)} >❌</button></div>
            <div className='padding-20px'> 
                <h3 className='text-align-left '>Add New Quote Document</h3>
                <p className='text-align-left'>Select a quote without a document to add a document. </p>
              
                    <select className = 'margin-top-5px' ref = {note_id}>
                    {quotes && quotes.map(quote => quote.file_name === null && (
                        <>
                          <option value = {quote.notes_id[0]}>{quote.description}</option>
                        </>
                    ))}
                    </select>
                    <div className='upload-div margin-top-5px max-width height-200px flex-container-el' onClick ={(e) => handleAttachFile (e)}>
                        <span>{fileName ? fileName : "Click me to attach a file!"}</span>
                        <input onChange = {updateFileName} ref = {fileUpload} type = 'file' hidden/>
                    </div>
                <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width " onClick = {(e) => handleUpload(e)} >Add New Document</button>
            </div>
    </div>
  )
}

export default AddQuotePopup