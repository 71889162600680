import React, {useRef} from 'react'

function EditVehicle({setCurrentPopup, driverData, vehicleData, api, getVehicles}) {
    const vehicle_id = useRef();
    const make = useRef();
    const model = useRef();
    const year = useRef();
    const vin = useRef();

    const updateInputFields = () => { 
        
       let vehicle_data = vehicleData.find( vehicle => vehicle.vehicle_id === vehicle_id.current.value );
      
       if(vehicle_data){ 
        make.current.value = vehicle_data.make;
        model.current.value = vehicle_data.model;
        year.current.value = vehicle_data.year;
        vin.current.value = vehicle_data.vin;
       }
        
    }


    const CREATE_FORM_OBJ = () => { 
        if(make.current.value && model.current.value && year.current.value && vin.current.value ){ 
            let formData = { 
                vehicle_id: vehicle_id.current.value,
                make : make.current.value, 
                model: model.current.value, 
                year: year.current.value, 
                vin: vin.current.value
            }
            return formData;
        }
        alert("Missing input fields")
    }
    const FETCH_API_ENDPOINT = async(formData) => { 
       
        const response = await fetch(`${api}/api/vehicle`, { 
            method: "PUT", 
            headers: { 
                'Content-Type': 'application/json'
            }, 
            body: JSON.stringify({formData})
        })
        return response;
    }

    const DELETE_API = async() => { 
        const response = await fetch(`${api}/api/vehicle?vehicle=${vehicle_id.current.value}`, { 
            method: "DELETE"
        })

        return response;
    }

    const EDIT_VEHICLE_BTN = async() => { 
        const formData = CREATE_FORM_OBJ();
        
        if(formData){ 
           
            let repsonse = await FETCH_API_ENDPOINT(formData); 
            if(repsonse.status === 500){ 
                 return alert("Server Side Error");
            }
            getVehicles();

            
        }
    }
    const DELETE_BTN = async() =>{ 
        const response = await DELETE_API();
        if(response.status === 200){ 
            getVehicles();
            return setCurrentPopup(null)
        }
        return alert("Error deleting vehicle")
    }

  return (
  
    <div className='background-color-full-dark border-radius-10px popup z-index ' >
    
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick = {() => setCurrentPopup(null)}>❌</button></div>
        <div className='flex-container-el padding-20px'> 
        <h3 className='text-align-left'> Edit Vehicle</h3>
        <label for = "firstname" className='background-color-full-dark text-align-left '>Select Vehicle </label>
        <select className='background-color-full-dark regularTextInput margin-top-5px  max-width' ref = {vehicle_id} onClick = {() => updateInputFields()} > 
            {vehicleData && vehicleData.map(vehicle => (
                <option value = {vehicle.vehicle_id}>{vehicle.year + " " + vehicle.make + " " + vehicle.model}</option>
            
            ))}
            </select>
            
            <label for = "firstname" className='background-color-full-dark text-align-left  '>Make</label>
            <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' autocomplete="off"  ref={make}/>
            <label for = "lastname" className='background-color-full-dark text-align-left margin-top-10px '>Model</label>
            <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' autocomplete="off" ref={model}/>
            <label for = "relation" className='background-color-full-dark text-align-left margin-top-10px'>Year</label>
            <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' autocomplete="off" ref={year}/>
            <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Vin</label>
            <input type = "text" className='background-color-full-dark regularTextInput margin-top-5px max-width'  autocomplete="off" ref={vin}/>
            <button type = "button" class = "button-default green-submit-btn margin-top-20px max-width "  onClick = {() => EDIT_VEHICLE_BTN()} >Edit </button>
            <button type = "button" class = "button-default green-submit-btn margin-top-20px max-width "  onClick = {() => DELETE_BTN()} >Delete</button>
        </div>
    </div>
  )
}

export default EditVehicle
