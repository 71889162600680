import {React, useState} from 'react'
import Add_new_question from './add_new_question';
import Add_new_test from './add_new_test';

function Question_bank_setting() {
    const [selectedTab, setSelectedTab] = useState();

    const tabs = [ <Add_new_question />, <Add_new_test />]
  return (
    <div className='flex-across'>
        <div className='flex-column-no-ho-centered bg-color-black-1 padding-10px height-100 margin-bottom-10 width-200px justify-content-space-between'>
            <div className='bg-color-black-1'>
                <h3 className='bg-color-black-1 margin-bottom-10'>Menu</h3>
                <li className='bg-color-black-1 margin-bottom-10 hover-cursor-pointer' onClick={() => setSelectedTab(0)}>Add New Question</li>
                <li className='bg-color-black-1 margin-bottom-10 hover-cursor-pointer' onClick={ () => setSelectedTab(1)}> Add New Test</li>
            </div>
            <div className='bg-color-black-1'> 
                <a href='/question-bank' className='bg-color-black-1'>Back To Home </a>
            </div> 
            
        </div>

        <div className='displayed_tab width-75-percent'>
        {tabs[selectedTab]}
        </div>
      
    </div>
  )
}

export default Question_bank_setting;
