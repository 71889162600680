import React, {useEffect, useState} from 'react'
import Cookies from 'js-cookie'
const { getViewedHouseholds} = require('../fetches/fetch');


function HouseholdViewedToday({ viewUserID, setCurrentHouseholdId, setToggleHouseholdActivityView, userIdAndFullName, setSwitchNumber }) {
    const [toDate, setDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [householdViewedData, setHouseholdViewedData] = useState();
    const async = async() => { 
        const response = await getViewedHouseholds(toDate, fromDate,  viewUserID); 
        const data = await response.json();
        setHouseholdViewedData(data)
    }
    useEffect(() =>{ 
        async();
    } , [toDate, fromDate])
  return (
    <>
        <div className='background-color-full-dark border-radius-10px popup z-index '>
            <div className = 'black-background  text-align-right border-radius-top-10px  '>
                <button type = "button" className='popup-exit-button' onClick = {()=> setToggleHouseholdActivityView(false)}  >❌</button>
            </div>
            <div className='padding-10px light-dark-background flex align-items-center'> 
                <label className='light-dark-background'>From:</label>
                <input type = 'date' className='light-dark-background margin-left-10px' onChange={(e) => setFromDate(e.target.value)}/>
                <label className='light-dark-background margin-right-10px '>To:</label>
                <input type = 'date' className='light-dark-background margin-left-10px' onChange={(e) => setDate(e.target.value)}/>
            </div>
            <div className = 'height-half margin-top-10px padding-10px'>
              <table>
                <thead>
                    <th>User</th>
                    <th>Primary Driver</th>
                    <th>Date Viewed</th>
                </thead>
                <tbody>
                    {householdViewedData && householdViewedData.map(data => ( 
                        <tr>
                            <td>{userIdAndFullName[data.user_id]}</td>
                            <td><u className='cursor-pointer' onClick = {() =>  {Cookies.set('currentHouseholdId', data.household_id[0]); setCurrentHouseholdId(data.household_id[0]);setSwitchNumber(0)}}>{data.full_name}</u></td>
                            <td>{new Date(data.date_viewed).toLocaleString()}</td>
                        </tr>
                    ))}

                </tbody>
              </table>

            </div>
        </div>
    </>
  )
}

export default HouseholdViewedToday