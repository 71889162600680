import React, {useEffect, useState, useRef} from 'react'
import Cookies from 'js-cookie'
import AddQuotePopup from '../src/quote_popup/addQuotePopup.js'
const {downloadFile } = require('./fetches/fetch.js')


function Quotes({userIdAndFullName, quotes,  fn, setCurrentPopup}) {


  useEffect(() => { 
   
    fn();
  
  }, [])

 


  return (
    <div className='overflow-wrapper' >
 
      {quotes && quotes.map(quote => ( 
        <div className='border-bottom'>
          <div className='flex-across-space-between padding-5px '>
            <div className='flex-basis-400px'> 
              {quote.description}
              <br/>
              <b>Date Created: </b>
              {new Date(quote.date_created).toLocaleString()}
            </div>
            <div   className = 'flex-basis-400px ' onClick = {()=> downloadFile(quote.document_name)} >
              <label className='text-align-left block-display cursor-pointer ' ><b>Quote Proposal:</b> {quote.file_name} </label>
              <label className='text-align-left block-display cursor-pointer'><b>Company Name:</b> {quote.company_name} </label>
            </div>
          </div>
        </div>
      ))}
      
    </div>
  )
}

export default Quotes