import React from 'react'

function Add_new_question() {

    const toggleById = (element) => { 

        element.hidden = !element.hidden;
    }




  return (
    <>
    <div className='background-color-full-dark border-radius-10px popup ' id = "new_question" hidden>
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => toggleById(document.getElementById('new_question'))}>❌</button></div>
        <div className='flex-container-el padding-20px'> 
            <h3 className='margin-bottom-10'>Add New Question</h3>
         <input type = "text" placeholder='Question' className='background-color-full-dark regularTextInput margin-bottom-10' />
         <input type = "text" placeholder='Option A' className='background-color-full-dark regularTextInput margin-bottom-10'/>
         <input type = "text" placeholder='Option B' className='background-color-full-dark regularTextInput margin-bottom-10'/>
         <input type = "text" placeholder='Option C' className='background-color-full-dark regularTextInput margin-bottom-10'/>
         <input type = "text" placeholder='Option D' className='background-color-full-dark regularTextInput margin-bottom-10'/>
         <label className='margin-bottom-10'>Test ID: </label>
         <input type = "text" placeholder='Option D' className='background-color-full-dark regularTextInput margin-bottom-10'/>
         <label className='margin-bottom-10'>Correct Answer:</label>
         <select className='background-color-full-dark regularTextInput max-width'> 
            <option value = "a" >A</option>
            <option value = "b">B</option>
            <option value = "c">C</option>
            <option value = "d">D</option>
         </select>
         <button type = "button" className='button-default green-submit-btn margin-top-20px max-width'> Add question</button>
        </div>
       </div>


    <div className='padding-10px'>
        <h3 className=' margin-bottom-10'>Add a question</h3>
      <table>
        <thead>
            <tr>
                <th onClick={() => toggleById(document.getElementById('new_question'))} className='hover-cursor-pointer'> + Question</th>
                <th>Date Created</th>
                <th>Test ID</th>
                <th>Answer A</th>
                <th>Answer B</th>
                <th>Answer C</th>
                <th>Answer D</th>
                <th>Correct Answer</th>
            </tr>
        </thead>
        <tbody> 
            <td> 
                Who is the first president of the United States Of America?
            </td>
            <td>
                6-18-2024
            </td>
            <td>
                20
            </td>
            <td>
                Abraham Lincoln
            </td>
            <td>
                Thomas Jefferson
            </td>
            <td>
                Bill Clinton
            </td>
            <td>
                George Washington
            </td>
            <td className='color-orange'>
                D
            </td>
        </tbody>
      </table>
    </div>
    </>
  )
}

export default Add_new_question
