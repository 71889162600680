import {React, useRef, useState, useEffect} from 'react'
import Cookies from 'js-cookie'

function AddAutomobilePolicyPopup({ householdData, userIdAndFullName, insurance_companies, setCurrentPopup, POST_POLICY, fetchData, vehicleData, driverData}) {
    const [items, setItems] = useState(0);
    const POLICY_NUMBER = useRef();
    const POLICY_STATUS = useRef();
    const POLICY_ADDRS = useRef();
    const POLICY_COMPANY = useRef();
    const EFFECTIVE_DATE = useRef();
    const BI_LIMITS = useRef();
    const PD_LIMTIS = useRef();
    const PIP_LIMITS = useRef();
    const PIP_DEDUCTIBLE = useRef();
    const TOTAL_PREM = useRef();
    const EXP_DATE = useRef();
    const primary_driver = useRef();
    const comm_prem = useRef();
    const user_id = useRef();
    const [policyAddress, setPolicyAddress] = useState('');

    useEffect(() => {
      if (householdData && householdData.address_line_one) {
          setPolicyAddress(householdData.address_line_one);
      }
  }, [householdData]);


    const handleCheck = async(event) => { 
      if(event.target.checked){ 
        setItems(items + 1)
      }else { 
        setItems(items - 1);
      }

    }


    const CREATE_AUTO_POLICY_OBJ = () => { 
      if(TOTAL_PREM.current.value.includes('$')){ 
        TOTAL_PREM.current.value = TOTAL_PREM.current.value.replace(/\$/g, '');
      }
        if(primary_driver.current.value && POLICY_NUMBER.current.value && POLICY_STATUS.current.value && POLICY_ADDRS.current.value && POLICY_COMPANY.current.value && EFFECTIVE_DATE.current.value && TOTAL_PREM.current.value && items > 0 && EXP_DATE.current.value  ){
         
          
            let POLICY_OBJ = { 
                user_id: Cookies.get('userid'),
                policy_type: "Automobile",
                household_id: Cookies.get('currentHouseholdId'),
                policy_number: POLICY_NUMBER.current.value, 
                status: POLICY_STATUS.current.value, 
                address_line_2: POLICY_ADDRS.current.value,
                effective_date: EFFECTIVE_DATE.current.value,
                bi_limits: BI_LIMITS.current.value,
                pd_limits: PD_LIMTIS.current.value,
                pip_limits: PD_LIMTIS.current.value,
     
                pip_deductible: PIP_DEDUCTIBLE.current.value,
                company: POLICY_COMPANY.current.value,
                items: items,
                expiration_date: EXP_DATE.current.value,
                total_prem: TOTAL_PREM.current.value, 
                primary_driver: primary_driver.current.value, 
                comm_prem: comm_prem.current.value ? comm_prem.current.value : null, 
                

            } 
            return POLICY_OBJ;
        } else { 
            alert('Missing a required field!')
            return;
        }
    }

    const CREATE_NEW_AUTO_POLICY_BTN = async() => { 
        const POLICY_OBJ = CREATE_AUTO_POLICY_OBJ();
        if(POLICY_OBJ){ 
            const response = await POST_POLICY(POLICY_OBJ);
            if(response.status === 500){ 
                alert('Policy Number invalid or already exists!')
            }else { 
                fetchData();
                setCurrentPopup(null)
            }

          }
    }

  return (
    
    <div className='background-color-full-dark border-radius-10px popup ' id = "automobile-popup">
  
    <div className = 'black-background  text-align-right border-radius-top-10px  '><button type = "button" className='popup-exit-button' onClick = {()=> setCurrentPopup(null)}  >❌</button></div>
      <div className=' padding-20px '> 
      <h3 className='text-align-left margin-bottom-10'>Add Automobile Policy</h3>
        <div className='height-half flex-column-no-ho-centered  padding-20px'>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5pxpx  '>Select Vehicles*</label>
        {vehicleData && vehicleData.map((vehicle, index) =>  (
          <>
          <div className='flex-across-space-between max-width'>
            <p >{vehicle.year + " " + vehicle.make + " " + vehicle.model }</p>
            <input type = "checkbox" name = "select_vehicle"  onChange={handleCheck}/>
          </div>
          </>
        ))}
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5pxpx  ' >Policy Number*</label>
        <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width  ' autocomplete="off" ref={POLICY_NUMBER}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Policy Status*</label>
          <select className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width'  autocomplete="off" ref={POLICY_STATUS}>
            <option value = "Active">Active</option>
            <option value = "Active">Cancelled</option>
            <option value = "Active">Pending Cancel</option>
            <option value = "Active">Terminated</option>
          </select>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Policy Address*</label>
        <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width ' 
        autocomplete="off" 
        ref={POLICY_ADDRS} 
        value={policyAddress}
        onChange={(e) => setPolicyAddress(e.target.value)}
        />
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Policy Company*</label>
          <select  className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width ' autocomplete="off" ref={POLICY_COMPANY}>
            {insurance_companies && insurance_companies.map(company => ( 
              <option  value = {company} >{company} </option>
            ))}
          </select>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Effective Date*</label>
        <input type = 'date' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width'  autocomplete="off" ref={EFFECTIVE_DATE}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Prem Total*</label>
        <input type = 'number' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width' placeholder="$0.00"  autocomplete="off" ref={TOTAL_PREM}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Expiration Date*</label>
        <input type = 'date' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width'  autocomplete="off" ref={EXP_DATE}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Primary Driver*</label>
        <select className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width' placeholder="$0.00"  autocomplete="off" ref={primary_driver}>
         {driverData && driverData.map(driver => ( 
          <option value = {driver.drivers_id}>{driver.full_name}</option>
         ))}
        </select>

        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Commissionable Prem</label>
        <input type = 'number' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width'  autocomplete="off" ref={comm_prem}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>BI Limits</label>
        <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width'  autocomplete="off" ref={BI_LIMITS}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>PD Limits</label>
        <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width'  autocomplete="off" ref={PD_LIMTIS}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>PIP Limits</label>
        <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width'autocomplete="off" ref={PIP_LIMITS}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>PIP Deductible</label>
        <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width'  autocomplete="off" ref={PIP_DEDUCTIBLE}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Date Bound</label>
        <span>{new Date().toLocaleDateString()}</span>
      
        </div>
        <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width"  onClick={() =>  CREATE_NEW_AUTO_POLICY_BTN()}  >Add new policy</button>
      </div>
    </div>
  )
}

export default AddAutomobilePolicyPopup


