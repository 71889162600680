import React, {useState, useEffect} from 'react'
import DatePicker from './DatePicker'
const {get_payroll} = require('./fetches/fetch')


function Payroll({userIdAndFullName, allUserData}) {
    const today = new Date();
    const [startDate, setStartDate] = useState(today.getDate() < 15 ? new Date(today.getFullYear(), today.getMonth(), 1) : new Date(today.getFullYear(), today.getMonth(), 16)); 
    const [endDate, setEndDate] = useState(today.getDate() < 15 ? new Date(today.getFullYear(), today.getMonth(), 15) : new Date(today.getFullYear(), today.getMonth() + 1, 0) );
    const [payrollData, setPayrollData] = useState();
    const [totalPayout, setTotalPayout] = useState();
    const formattedStartDate = startDate ? new Date(startDate).toISOString().slice(0, 10) : ''
    const formattedEndDate = endDate ? new Date(endDate).toISOString().slice(0, 10) : '';
    let totalPay;

    useEffect(()=> { 

        async function fn() { 
            const response = await get_payroll(new Date(startDate).toISOString(), new Date(endDate).toISOString());
            const data = await response.json()
            setPayrollData(data)


            const total = data.reduce((acc, userPayrollData) => {
              if(userPayrollData.on_payroll === true){ 
                const hourlyRate =
                  allUserData.find(
                    (user) => userPayrollData.user_id === String(user.id) 
                  )?.hourly_rate || 0;
                const payForRow =
                  userPayrollData.total_hours * hourlyRate +
                  (userPayrollData.total_minutes / 60) * hourlyRate;
                return acc + payForRow;
                }
                 return acc;
              }, 0);
            
        
              setTotalPayout(total);
        }
        fn();
   
    }, [startDate, endDate])

    const handleClear = () => { 
        setStartDate(today.getDate() < 15 ? new Date(today.getFullYear(), today.getMonth(), 1) : new Date(today.getFullYear(), today.getMonth(), 16)); 
        setEndDate(today.getDate() < 15 ? new Date(today.getFullYear(), today.getMonth(), 15) : new Date(today.getFullYear(), today.getMonth() + 1, 0));
    }
  
  return (
    
    <div>
      
        <div className = 'flex align-items-center light-dark-background margin-bottom-10  '>
            <b><p className='light-dark-background padding-5px'>From:</p></b>
            <input type= 'date'  className ='padding-5px datepicker' value = {formattedStartDate} onChange = {(e) => setStartDate(e.target.value)} />
            <b><p className='light-dark-background' padding-5px>To:</p></b>
            <input type= 'date' className ='padding-5px datepicker' value = {formattedEndDate} onChange = {(e) => setEndDate(e.target.value) }/>
            <b><p className='light-dark-background padding-right-10px cursor-pointer' onClick = { () => handleClear()} >Clear</p></b>
        </div>
        <div><h3 className = ' margin-bottom-10'>Total Est. Payout - ${totalPayout ? totalPayout.toFixed(2) : "Loading..."}</h3> {totalPay}</div>
        
        <table>
            <thead> 
                <th>
                    Employee
                </th>
                <th>
                   Total Hours And Minutes
                </th>
                <th>
                Total Pay 
                </th>
            </thead>
            <tbody>
            {payrollData && payrollData.map(userPayrollData => {


                  return userPayrollData.on_payroll === true ? ( 
                    <tr>
                     
                        <td>{userIdAndFullName[userPayrollData.user_id]}</td>
                        <td>{userPayrollData.total_hours}hrs {userPayrollData.total_minutes}m</td>
                        <td> ${(userPayrollData.total_hours * allUserData.filter(user => userPayrollData.user_id === String(user.id))[0]?.hourly_rate +  userPayrollData.total_minutes / 60 * allUserData.filter(user => userPayrollData.user_id === String(user.id))[0]?.hourly_rate).toFixed(2)}</td>
                        </tr> 
                   ) : null }
                  
                ) }

            </tbody>
           
        </table>
    </div>
  )
}

export default Payroll