import React from 'react'
import { useSelector, useDispatch } from 'react-redux'; 
import {toggle} from './redux/toggleSettings'
import Settings from './sidebar_popups/Settings.tsx'
import SpaceDashboardRoundedIcon from '@mui/icons-material/SpaceDashboardRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import CardTravelRoundedIcon from '@mui/icons-material/CardTravelRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import PersonIcon from '@mui/icons-material/Person';
function Sidebar({setSwitchNumber, currentUserData, setToggleAddAnnouncement, newLeads }) {
  let user;
  interface toggleSettings { 
    toggleSettings: { 
      toggle:boolean;
    }
  }
  const dispatch = useDispatch();
 
  if(currentUserData){ 
    user = currentUserData[0]
  }

  const displaySettings= useSelector((state:toggleSettings) => state.toggleSettings.toggle);
  const handleSettingsToggle = () =>{ 
    dispatch(toggle())
  }

  return (
   <>
    {displaySettings && <Settings />}
   <div className=' bg-color-black-1 max-height-sb flex-across-space-between flex-column-no-ho-centered max-height-vh '> 
        <div className='bg-color-black-1 flex-column-no-ho-centered '>
          <div className = "padding-10px cursor-pointer fade-in bg-color-black-1 display-flex align-items-center" onClick={() => setSwitchNumber(7)}> 
            <SpaceDashboardRoundedIcon 
            sx= {{ bgcolor: 'black'} }
            />
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" >Dashboard </span>
          </div>

          <div className = "padding-10px cursor-pointer fade-in bg-color-black-1 display-flex align-items-center" onClick={() => setSwitchNumber(1)}> 
            <AccountBoxRoundedIcon
            sx= {{ bgcolor: 'black'} }
            />
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" >Leads </span>
          </div>

          <div className = "padding-10px cursor-pointer fade-in bg-color-black-1 display-flex align-items-center" onClick={() => setSwitchNumber(2)}> 
            <LocalPhoneRoundedIcon 
            sx= {{ bgcolor: 'black'} }
            />
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" >Pipeline </span>
          <span className='bg-color-black-1 color-red'>{newLeads === true ? "New  " : null} </span>
          </div>


          {user &&  user.role === "Admin"  ? 
          <div className = "padding-10px cursor-pointer fade-in bg-color-black-1 display-flex align-items-center" onClick={() => setSwitchNumber(3)}> 
            <PersonIcon
            sx= {{ bgcolor: 'black'} }
            /> 
          
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" >User Management</span>
         
          </div>: null } 

          <div className = "padding-10px cursor-pointer fade-in bg-color-black-1 display-flex align-items-center" onClick={() => setSwitchNumber(5)}> 
            <MenuBookRoundedIcon
            sx= {{ bgcolor: 'black'} }
            />
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" >Agentz Resources </span>
          </div>


          <div className = "padding-10px cursor-pointer fade-in bg-color-black-1 display-flex align-items-center" onClick={() => setSwitchNumber(8)}> 
            <AlarmRoundedIcon
            sx= {{ bgcolor: 'black'} }
            />
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" >Agentz Management </span>
          </div>

          <div className = "padding-10px cursor-pointer fade-in bg-color-black-1 display-flex align-items-center" onClick={() => setSwitchNumber(4)}> 
            <HelpRoundedIcon
            sx= {{ bgcolor: 'black'} }
            />
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" >Tech Support </span>
          </div>

          <div className = "padding-10px cursor-pointer fade-in bg-color-black-1 display-flex align-items-center"> 
            <CardTravelRoundedIcon
            sx= {{ bgcolor: 'black'} }
            />
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" >Providers </span>
          </div>

          <div className = "padding-10px cursor-pointer fade-in bg-color-black-1 display-flex align-items-center"> 
            <BarChartRoundedIcon
            sx= {{ bgcolor: 'black'} }
            />
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" >Charts </span>
          </div>


          <div className = "padding-10px cursor-pointer fade-in bg-color-black-1 display-flex align-items-center" onClick={() => setSwitchNumber(6)}> 
            <CalendarMonthRoundedIcon
            sx= {{ bgcolor: 'black'} }
            />
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" >Calender </span>
          </div>
          {user && user.role === "Admin" ?
          <div className = "padding-10px cursor-pointer fade-in bg-color-black-1 display-flex align-items-center" onClick={() => setToggleAddAnnouncement(true)}> 
            <CampaignRoundedIcon
            sx= {{ bgcolor: 'black'} }
            />
            
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" >Announcement </span>
          </div> : null} 
          
          
          
          
          
          
        </div>
        <div className='bg-color-black-1 flex-column-no-ho-centered'>
          <span className='padding-10px fade-in bg-color-black-1 cursor-pointer' onClick={handleSettingsToggle}>{currentUserData && currentUserData[0].full_name}</span>
         <span className='padding-10px fade-in bg-color-black-1 font-size-12px '>Agentz by SquareWealth LLC©</span>
         </div>
    </div>
    </>
  )
}

export default Sidebar
