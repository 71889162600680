import React from 'react'

function SelectPolicyTypePopup({setCurrentPopup, setPolicyType}) {
  const handleSelectChange = (e) => { 
    switch(e.target.value){ 
      case 'Automobile': 
        setCurrentPopup(3);
        break;
      case 'Motorcycle': 
        setCurrentPopup(2);
        break;
      case 'Jewelry': 
        setCurrentPopup(4);
        break;
      case 'Home': 
        setCurrentPopup(5); 
        break;
      case 'Renters': 
        setCurrentPopup(10);
        break;
      default : 
        setPolicyType(e.target.value); 
        setCurrentPopup(11); 
        break;

      


    }
  }
  return (
    <div className='background-color-full-dark border-radius-10px popup  ' id = "select-policy-type-popup" >
<div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)} > ❌</button></div>
  <div className = ' '> 
    <div className='flex-container-el padding-20px '>
      <select className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onChange = {(e) => handleSelectChange(e)}>
        <option value=''>Select Policy Type</option>
        <option value='Automobile'>Automobile</option>
        <option value='Boat'>Boat</option>
        <option value='Motorcycle'>Motorcycle</option>
        <option value='Jewelry'>Jewelry</option>
        <option value='Home'>Home</option>
        <option value='Condo'>Condo</option>
        <option value='Renters'>Renters</option>
        <option value='PUP'>PUP</option>
        <option value='Health'>Health</option>
        <option value='General Liability'>General Liability</option>
        <option value='Business Owners Package'>Business Owners Package</option>
        <option value='Disability'>Disability</option>
        <option value='Life'>Life</option>
        <option value='Umbrella'>Umbrella</option>
        <option value='Event'>Event</option>
        <option value='Comprehensive Personal Liability'>Comprehensive Personal Liability</option>
        <option value='Flood'>Flood</option>
        <option value='Recreational Vehicles'>Recreational Vehicles</option>
        <option value='Mobile Home'>Mobile Home</option>
        <option value='Landlords'>Landlords</option>
        <option value='Annuity'>Annuity</option>
        <option value='Fixed Annuity'>Fixed Annuity</option>
        <option value='Variable Annuity'>Variable Annuity</option>
        <option value='Index Annuity'>Index Annuity</option>
        <option value='Excess Liability'>Excess Liability</option>
        <option value='Errors and Omissions'>Errors and Omissions</option>
        <option value='Directors and Officers'>Directors and Officers</option>
        <option value='Crime'>Crime</option>
        <option value='Surety Bond'>Surety Bond</option>
        <option value='Miscellaneous Bond'>Miscellaneous Bond</option>
        <option value='Package'>Package</option>
        <option value='Property'>Property</option>
        <option value='Condominium'>Condominium</option>
        <option value='Inland Marine'>Inland Marine</option>
        <option value='Installation Builders Risk'>Installation Builders Risk</option>
        <option value='Boat Owners'>Boat Owners</option>
        <option value='Property (Dwelling Fire)'>Property (Dwelling Fire)</option>
        <option value='Property (Off-Road Vehicles)'>Off-Road Vehicle</option>
        <option value='Small Commercial Package'>Small Commercial Package</option>
        <option value='Glass'>Glass</option>
        <option value='Schedule Personal Property'>Schedule Personal Property</option>
        <option value='Earthquake'>Earthquake</option>
        <option value='Long-Term Care'>Long-Term Care</option>
        <option value='NSF'>NSF</option>
        <option value='Mexican Travel'>Mexican Travel</option>
        <option value='Roadside'>Roadside</option>
        <option value='Excess and Surplus'>Excess and Surplus</option>
        <option value='Builders Risk'>Builder's Risk</option>
        <option value='Pet Health'>Pet Health</option>
        <option value='Contract'>Contract</option>
        <option value='OTHER'>OTHER</option>
        <option value='Windowstorm'>Windowstorm</option>
        <option value='Workers Comp'>Workers Comp</option>
        
   
      </select>
     
    </div>
  </div>
</div>
  )
}

export default SelectPolicyTypePopup

