import {React, useState} from 'react'
import Home from './resourceTabs/home';
import StudyGuides from './resourceTabs/studyGuides';
import News from './resourceTabs/news';
import Uw_guidelines from './resourceTabs/uw_guidelines';
import Careers from './resourceTabs/careers';
import Forms from './resourceTabs/forms'
import Public_record_links from './resourceTabs/public_record_links';
import Carriers from './resourceTabs/carriers';
import About from './resourceTabs/about';
import Services from './resourceTabs/services';
import Contact from './resourceTabs/contact';

function Resources({api, currentUserData}) {

  const [currentTab, setCurrentTab] = useState(0);




  const tabs =[ <Home />, <StudyGuides />, <News />, <Uw_guidelines />, <Careers />, <Forms />, <Public_record_links />, <Carriers />, <About />, <Services />, <Contact /> ]
  return (
    <>
        <div className='side-bar flex-across max-height'>
            <div className='flex-column-no-ho-centered bg-color-black-1  padding-10px '>
                <a href="#home" className='bg-color-black-1 text-decoration-none margin-bottom-10' onClick={ () => setCurrentTab(0)} >Home</a>
                <a href="#study-guides" className='bg-color-black-1 text-decoration-none margin-bottom-10'  onClick={ () => setCurrentTab(1)}>Study Guides</a>
                <a href="#news" className='bg-color-black-1 text-decoration-none margin-bottom-10' onClick={ () => setCurrentTab(2)}>News</a>
                <a href="#uw-guidelines" className='bg-color-black-1 text-decoration-none margin-bottom-10' onClick={ () => setCurrentTab(3)}>UW Guidelines</a>
                <a href="#careers" className='bg-color-black-1 text-decoration-none margin-bottom-10'onClick={ () => setCurrentTab(4)} >Careers</a>
                <a href="#forms" className='bg-color-black-1 text-decoration-none margin-bottom-10' onClick= {() => setCurrentTab(5)}>Forms</a>
                <a href="#public-records-links" className='bg-color-black-1 text-decoration-none margin-bottom-10' onClick= {() => setCurrentTab(6)}>Public Records Links</a>
                <a href="#carriers" className='bg-color-black-1 text-decoration-none margin-bottom-10' onClick= {() => setCurrentTab(7)}>Carriers</a>
                <a href="#about" className='bg-color-black-1 text-decoration-none margin-bottom-10' onClick= {() => setCurrentTab(8)} >About</a>
                <a href="#services"  className='bg-color-black-1 text-decoration-none margin-bottom-10' onClick= {() => setCurrentTab(9)}>Services</a>
                <a href="#contact" className='bg-color-black-1 text-decoration-none margin-bottom-10' onClick= {() => setCurrentTab(10)}>Contact</a>
            </div>

            <div className='padding-20px secondary-color max-width'> 
            { tabs[currentTab] }

            </div>
        </div>
    </>
  )
}

export default Resources
