import React, {useRef} from 'react'

function PUPCoverage({policies, setCurrentCoveragePopup, sendCoverageData, fetchCoverages}) {
    const excess_liability = useRef();
    const uninsured_motorist = useRef();
    const aggregated_limit  = useRef();
    const belong_to_policy = useRef();


    const CREATE_PUP_COV_OBJ = () => { 

        if(belong_to_policy.current.value ){ 
            let PUP_COV_OBJ = {
                type: "PUP",
                belong_to_policy: belong_to_policy.current.value,
                excess_liability: excess_liability.current.value, 
                uninsured_motorist: uninsured_motorist.current.value, 
                aggregated_limit: aggregated_limit.current.value
            } 
            return PUP_COV_OBJ;

        }else { 
            return alert('Missing Policy Number or Items!')
        }

    }

    const CREATE_NEW_JEWEL_COV_BTN = async() => { 
        const obj = CREATE_PUP_COV_OBJ();
       
        const response = await sendCoverageData(obj); 
        if(response.status === 500 ){ 
            return alert('Error Creating New Coverage! Check connection!')
        }else { 
            fetchCoverages('PUP', belong_to_policy.current.value);
            setCurrentCoveragePopup(null);
        }
    }
    



  return (
    <div className='background-color-full-dark border-radius-10px popup'   >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentCoveragePopup(null)} >❌</button></div>
            <div className='flex-container-el padding-20px'> 
                <h3 className='text-align-left margin-bottom-10'>Add PUP Coverage </h3>
                <label  className='background-color-full-dark text-align-left   margin-top-5px '>Policy Number:</label>
                <select className='background-color-full-dark regularTextInput margin-top-5px max-width ' ref={belong_to_policy} >
                {policies && policies.map(policy => (
                    <option values = {policy.policy_id}>{policy.policy_id}</option>
                )
                )}
                </select>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Excess Liability</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width' autocomplete="off" ref={excess_liability}/>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px'>Uninsured Motorist</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width' autocomplete="off" ref = {uninsured_motorist}/>
                <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px'>Aggregated Limit</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width'  autocomplete="off" ref= {aggregated_limit}/>
                <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width"  onClick={() =>CREATE_NEW_JEWEL_COV_BTN()} >Add new coverage</button>
            </div>
      </div>)}

export default PUPCoverage
