import React from 'react'

function Add_new_test() {

    const toggleById = (element) => { 

        element.hidden = !element.hidden;
    }


  return (
    <>
    <div className='background-color-full-dark border-radius-10px popup ' id = "new_question" hidden>
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => toggleById(document.getElementById('new_question'))}>❌</button></div>
        <div className='flex-container-el padding-20px'> 
            <h3 className='margin-bottom-10'>Add New Test</h3>
         <input type = "text" placeholder='Test Name' className='background-color-full-dark regularTextInput margin-bottom-10' />
         <input type = "text" placeholder='Duration (Minutes)' className='background-color-full-dark regularTextInput margin-bottom-10'/>
         <input type = "text" placeholder='# Of Questions' className='background-color-full-dark regularTextInput '/>

         <button type = "button" className='button-default green-submit-btn margin-top-20px max-width'> Add Test</button>
        </div>
       </div>


    <div className='padding-10px'>
        <h3 className=' margin-bottom-10'>Add a question</h3>
      <table>
        <thead>
            <tr>
                <th onClick={() => toggleById(document.getElementById('new_question'))} className='hover-cursor-pointer'> + Test Name</th>
                <th>Date Created</th>
                <th>Test ID</th>
                <th># Of Questions</th>
                <th>Duration (minutes)</th>
            </tr>
        </thead>
        <tbody> 
            <td> 
                Entrace Exam
            </td>
            <td>
                6-18-2024
            </td>
            <td>
                20
            </td>
            <td>
                15
            </td>
            <td>
                60
            </td>
          
        </tbody>
      </table>
    </div>
    </>
  )
}

export default Add_new_test
