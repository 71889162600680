import React from 'react'

function Careers() {
  return (
    <div>
        <h3 className='secondary-color'>Careers</h3> 
        <p className='secondary-color'>Explore career oppurtunities.</p>
    </div>
  )
}

export default Careers;
