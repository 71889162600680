import React, {useRef} from 'react'
import { postOrganization } from '../fetches/fetch'; 
import Cookies from 'js-cookie'




function ChangeOrg({setCurrentPopup}) {
  const org_name_value = useRef(null);

  const handlePostOrganization = async() => { 
    const respsonse = await postOrganization(Cookies.get('currentHouseholdId'), org_name_value.current.value);
    if(respsonse.status === 500) return alert("Error adding orgnization name!");
    setCurrentPopup(null);
  }
  return (
    <div className='background-color-full-dark border-radius-10px popup'  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)} >❌</button></div>
            <div className='flex-container-el padding-20px'> 
                <h3 className='text-align-left'>Change Organization</h3>
                <label for = "firstname" className='background-color-full-dark text-align-left margin-top-5px  '>Name</label>
                <input type = 'text' ref = {org_name_value} className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'></input>

                <button type = "button" class = "button-default green-submit-btn margin-top-20px max-width" onClick={() => handlePostOrganization()} >Change Organization</button>
        </div>
       </div>
  )
}

export default ChangeOrg
