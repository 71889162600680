import React, {useEffect, useState, useRef} from 'react'
import Cookies from 'js-cookie';
const {getPoliciesByUserId} = require('../fetches/fetch');

function PoliciesRelatedToUserPopup({setViewUserID, viewUserID, setTogglePoliciesPopup, currentUserData, userIdAndFullName, setCurrentHouseholdId, setSwitchNumber}) {
    const [policies, setPolicies] = useState();

    const [computedPremTotal, setComputedPremTotal] = useState();
    const [computedItemsTotal, setComputedItemsTotal] = useState();
    const [commisionablePremTotal, setCommissionablePremTotal] = useState();
    const [numberOfPoliciesTotal, setNumberOfPoliciesTotal] = useState();
   


    const fromDate = useRef();
    const toDate = useRef();

    useEffect(() => { 
       
        asyncFunc();
        
    }, [viewUserID])

    const asyncFunc = async() => { 
        if(fromDate.current.value > toDate.current.value ){ 
           alert('From date cannot be newer than To date!'); 
          fromDate.current.value = null; 
          toDate.current.value = null;
          asyncFunc();
          return;
        }
      
            const response = await getPoliciesByUserId(viewUserID, fromDate.current.value, toDate.current.value);
            const data = await response.json();
            setPolicies(data);
            computeTotals(data);
       

    }


    const handleDateChange = () => { 
    
        asyncFunc();
        
    }

    const computeTotals = (policies) => { 
        if(policies){ 
            setComputedPremTotal(policies.reduce((total, policy) => total + (policy.total_prem || 0), 0));
            setComputedItemsTotal(policies.reduce((total, policy) => total + (policy.items || 0), 0));
            setCommissionablePremTotal(policies.reduce((total, policy) => total + (policy.commissionable_premium || 0), 0));
            
            setNumberOfPoliciesTotal(policies.length);
        }
    }
   const clearFilter = () => { 
    fromDate.current.value = null; 
    toDate.current.value = null;
    asyncFunc();
   }
  return (
    <>
  
    <div className='background-color-full-dark border-radius-10px popup z-index '>
        <div className = 'black-background  text-align-right border-radius-top-10px  '>
            <button type = "button" className='popup-exit-button' onClick = {()=> setTogglePoliciesPopup(false)}  >❌</button>
        </div>

        <div className=' padding-20px light-dark-background  '> 
            
           <div>
            <h3 className='light-dark-background'>Performance</h3>
                <p className = 'light-dark-background'>- Prem Total: {computedPremTotal ? '$' + Number(computedPremTotal).toFixed(2) : null }</p>
                <p className = 'light-dark-background'>- Items Total: {computedItemsTotal}</p>
                <p className = 'light-dark-background'>- Number Of Policies: {numberOfPoliciesTotal}</p>
                <p className = 'light-dark-background'>- Base Commission Total:{commisionablePremTotal ?  ' $' + Number(commisionablePremTotal * .04).toFixed(2) : null}</p>
           </div>
           <div className='light-dark-background display-flex align-items-center'>
                <span className='light-dark-background'>- From: </span><input className='light-dark-background' type = 'date' name = 'from '  ref = {fromDate} />
                <span className='light-dark-background '>To:</span><input  className='light-dark-background' type = 'date' name = 'to' ref = {toDate} />
             
           </div >
           {currentUserData && currentUserData[0].role === 'Admin' && ( 
            <>
                <span className='light-dark-background' >- Filter By User: </span>
                <select className='light-dark-background margin-bottom-10'  onChange = {(e) => {setViewUserID(e.target.value)}}>
                    <option value = {''}>All Users</option>
                        {Object.keys(userIdAndFullName).map(userID => userID !== viewUserID ? (
                            <option value = {userID}>{userIdAndFullName[userID]}</option>
                        ): <option selected value = {viewUserID}>{userIdAndFullName[viewUserID]}</option> )}
                        
                </select>
            </>
           )}
           <div className='light-dark-background  '>
            <button onClick = {(e) => handleDateChange(e)} className='green-submit-btn'>Filter</button>
            <button onClick = {() => clearFilter()} className='green-submit-btn'>Clear</button>
           </div>
           <div className='height-half'>
            <table className = 'th-style '>
                <thead>
                        <th >Primary Driver Name</th>
                        <th >Policy Number</th>
                        <th >Policy Type</th>
                        <th >Date Bound</th>
                        <th>Producer</th>
                        <th >Company</th>
                        <th >Effective Date</th>
                        <th >Status</th>
                        <th>Prem Total</th>
                </thead>
                        <tbody id="search-results_table_body"> 
                        {policies ? policies.map(policy => (
                            <>
                            <tr onClick = {() => {Cookies.set('currentHouseholdId', policy.household_id[0]); setCurrentHouseholdId(policy.household_id[0]); setSwitchNumber(0) }}>
                                <td>{policy.firstname + " " + policy.lastname}</td>
                                <td>{policy.policy_id_two}</td>
                                <td>{policy.policy_type}</td>
                                <td>{new Date(policy.date_created[0]).toLocaleDateString('en-US', {timeZone: 'UTC'})}</td>
                                <td>{userIdAndFullName[policy.user_created_id]}</td>
                                <td>{policy.company}</td>
                                <td>{new Date(policy.effective_date ).toLocaleDateString('en-US', {timeZone: 'UTC'})}</td>
                                <td>{policy.status}</td>
                                <td>${policy.total_prem}</td>
                            </tr>
                            </>
                        )): null}
                        </tbody>
                    </table>
                    </div>
            

        </div>
    </div>
    </>
  )
}

export default PoliciesRelatedToUserPopup
