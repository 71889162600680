import React from 'react'

function Services() {
  return (
    <div>
        <h3 className='secondary-color'>Services</h3> 
        <p className='secondary-color'>Details about our services</p>
    </div>
  )
}

export default Services
