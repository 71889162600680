import React, { useRef} from 'react'
const {postAnnouncement} = require('../fetches/fetch')

function Add_story({setToggleAddAnnouncement}) {
    const textArea = useRef(null);
    
    const submitAnnouncement = async() => { 
        try {
            const response = await postAnnouncement(textArea.current.value);
            if(response.status !== 200){ 
               throw new Error
            }
            return setToggleAddAnnouncement(false)
        } catch (error) {
            return alert('Error creating announcement!')
        }
    }
  
    
  return (
    <div className='background-color-full-dark border-radius-10px  popup z-index'  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick= { () => setToggleAddAnnouncement(null)}>❌</button></div>
            <div className='flex-container-el padding-10px'> 
            <h3 className='padding-10px'>Add announcement</h3>
            <textarea className='width-400px height-200px' ref = {textArea} ></textarea>
            <button type = "button" className='button-default green-submit-btn margin-top-20px max-width padding-10px' onClick = {( )=> submitAnnouncement() }  >Add</button>
        </div>
    </div>
  )
}

export default Add_story
