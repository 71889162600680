import React from 'react'

function NewLeads({newLead, j, acceptLead, index, dateOptions, newLeads}) {

    
return (
    <>
    
          <div className="flex-column-no-ho-centered light-dark-container text-align-center lead-card margin-left_50  grid-child ">
            {index === 0 ? <h3 className=' light-dark-container '>New Leads</h3> : null}
            <label  className={index === newLeads.length - 1 ? 'light-dark-container ': 'light-dark-container blur '}><b className={index === newLeads.length - 1  ? 'light-dark-container ': 'light-dark-container blur '}>Name:</b>
              <span className={index === newLeads.length - 1  ? 'light-dark-container ': 'light-dark-container blur'}> {" " + newLead?.firstname  + " " + newLead?.lastname}</span>
               </label>
            <label   className={index === newLeads.length - 1  ? 'light-dark-container ': 'light-dark-container blur '}><b className={index === newLeads.length - 1  ? 'light-dark-container ': 'light-dark-container blur'}>Date Created:</b> <span className={index === newLeads.length - 1  ? 'light-dark-container ': 'light-dark-container blur'}>{new Date(newLead?.date_created[0]).toLocaleString('en-US', dateOptions)}</span></label>
         
            <label className='light-dark-container'><b className={index === newLeads.length - 1  ? 'light-dark-container ': 'light-dark-container blur'}>Phone Number:</b> <span className={index === newLeads.length - 1  ? 'light-dark-container ': 'light-dark-container blur '}>{newLead?.home_phone}</span></label>
            <button type="button" className='button-default green-submit-btn margin-top-30px max-width' onClick={() => acceptLead(newLead?.household_id[0])}>Accept Lead</button>
          </div>
    </>
)
}

export default NewLeads
