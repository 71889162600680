import React from 'react'

function ConfirmDelete({action, setToggleDelete}) {

    const handleDelete = async() => { 
        if(action){ 
        await action();
        setToggleDelete(false)
        }
    } 
  return (
    <> 
    
    <div className='background-color-full-dark border-radius-10px  popup z-index'  >
       <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick= { () => setToggleDelete(false)}>❌</button></div>
           <div className='flex-container-el'> 
           <h3 className='padding-10px'>Warning! Please confirm!</h3>
           
           <button type = "button" className='button-default red-submit-btn margin-top-20px max-width padding-10px' onClick = {() => handleDelete() } >Delete </button>
           <button type = "button" className='button-default green-submit-btn margin-top-20px max-width padding-10px' onClick = {() =>  setToggleDelete(false)} >Cancel </button>
           
       </div>
   </div>
   </>
  )
}

export default ConfirmDelete