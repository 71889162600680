import React, {useRef} from 'react'

function Change_vendors({setCurrentPopup, api, checkedHouseholds}) {

  const vendor = useRef(null);


  const post_vendor = async() => { 
    const household_ids = [...checkedHouseholds]
    const response = await fetch(`${api}/api/household/vendor`, { 
        method: "POST", 
        headers: { 
            "Content-Type": "application/json"
        }, 
        body: JSON.stringify({
            household_ids:household_ids, 
            vendor_name: vendor.current.value

        })
    })
    return response;
  }

  const change_vendors = async() => { 
    if(checkedHouseholds.size === 0) return alert('Must select a household!');
    const response = await post_vendor(); 
    if(response.status === 500){ 
        return alert('Error changing vendor')
    }
    setCurrentPopup(null);
  }

  return (
   <> 
    <div className='background-color-full-dark border-radius-10px  popup z-index '  >
         <div className = 'black-background  text-align-right border-radius-top-10px  '><button type = "button" className='popup-exit-button' onClick= { () => setCurrentPopup(null)}>❌</button></div>
             <div className='flex-container-el'> 
                <h3 className='padding-10px'>Change Vendors </h3>
             <select className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' ref = {vendor}>
               <option value = "smart_financial">Smart Financial</option>
             </select>
             <button type = "button" className='button-default green-submit-btn margin-top-20px max-width padding-10px' onClick={()=> change_vendors()}>Change Vendors</button>
             
         </div>
     </div>
   </>
  )
}

export default Change_vendors
