import React, {useRef} from 'react'
import { setPrimaryDriver} from '../fetches/fetch'
import Cookies from 'js-cookie'


function SelectPrimaryDriver({setCurrentPopup, driverData, updateDrivers, getVehicles}) {
    const driver_id = useRef();
    const handleAssign = async() => { 
        const response = await setPrimaryDriver(driver_id.current.value, Cookies.get('currentHouseholdId'));
        if(response.status === 200){ 
           await  updateDrivers(Cookies.get('currentHouseholdId'));
           await getVehicles();
            return setCurrentPopup(null);
        }
        return alert('error updating primary driver!');
        
    }
  return (
    <>
     <div className='background-color-full-dark border-radius-10px popup ' id = "add-driver-crud-ops"  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)}  >❌</button></div>
            <div className='flex-container-el padding-20px'> 
                <h3>Select Primary Driver</h3>
                <select  className=' background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'  autocomplete="off"  ref = {driver_id}>
                    {driverData && driverData.map(driver => ( <option value = {driver.drivers_id}>{driver.firstname + " " + driver.lastname}</option>)) }
                </select>
                <button type = "button" class = "button-default green-submit-btn margin-top-20px max-width " id = "add=driver-submit-btn" onClick = {() => handleAssign()}>Assign As Primary Driver</button>
        </div>
       </div>
    </>
  )
}

export default SelectPrimaryDriver
