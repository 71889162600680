import React, {useEffect, useRef} from 'react'

function Transfer_tasks({setCurrentPopup, api, userIdAndFullName  }) {
  const userOneID = useRef(null);
  const userTwoID = useRef(null);

  const postUsers = async()=> { 
    const response = await fetch(`${api}/api/user/task`, { 
      method: "POST", 
      headers: { 
        "Content-Type": "application/json"
      }, 
      body: JSON.stringify({
        userOne : userOneID.current.value,
        userTwo : userTwoID.current.value
      })
    })
    return response 
  }

  const transferTaskBtn = async()=> { 
   
    const response = await postUsers(); 
    if(response.status === 500){ 
      return alert('Error: Users selected dont have any task to transfer!')
    }
    setCurrentPopup(null);
  }
  
  return (

    <div className='background-color-full-dark border-radius-10px  popup z-inde width-400px'  >
     
    <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick= { () => setCurrentPopup(null)}>❌</button></div>
        <div className='flex-container-el padding-10px' > 
           <h3 className='padding-10px'>Transfer Task </h3>
           <div className='flex-across justify-content-space-between'>
            <div>
              <h4>From </h4>
            <select ref = {userOneID}>
               {userIdAndFullName && Object.entries(userIdAndFullName).map(([key, value])=> (
                <option value = {key}>{value}</option>
               ))}
            </select>
            </div>

            <div className='border-left'>
            <h3>To</h3>
              <select ref = {userTwoID}>
                {userIdAndFullName && Object.entries(userIdAndFullName).map(([key, value])=> (
                  <option value = {key}>{value}</option>
                ))}
              </select>
            </div>
            </div>
              
        <button type = "button" className='button-default green-submit-btn margin-top-20px max-width padding-10px' onClick={() => transferTaskBtn()} >Transfer tasks</button>
        
    </div>
</div>
  )
}

export default Transfer_tasks
