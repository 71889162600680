import React from 'react'
import { useSelector, useDispatch } from 'react-redux'; 
import {toggle} from '../redux/toggleSettings';
import '../index.css';
import Cookies from 'js-cookie';



export const changeTheme = (theme) => { 
    if(theme === 'blue'){
        document.documentElement.style.setProperty('--main-color', 'rgb(42, 42, 129)');
        document.documentElement.style.setProperty('--secondary-color', '#3c3ac0');
        document.documentElement.style.setProperty('--font-color', 'white');
        document.documentElement.style.setProperty('--third-color', 'black');
        document.documentElement.style.setProperty('--contrast-color', 'white');
        document.documentElement.style.setProperty('--main-accent', 'white');
        document.documentElement.style.setProperty('--second-accent', 'lightcoral');
        document.documentElement.style.setProperty('--button-color', 'black');
        document.documentElement.style.setProperty('--button-color-hover', 'orange');

     }

     if(theme === 'dark' || theme === 'undefined'){ 
        // Set multiple CSS variables
        document.documentElement.style.setProperty('--main-color', '#121212');
        document.documentElement.style.setProperty('--secondary-color', '#1F1F1F');
        document.documentElement.style.setProperty('--font-color', 'white');
        document.documentElement.style.setProperty('--third-color', 'black');
        document.documentElement.style.setProperty('--contrast-color', 'white');
        document.documentElement.style.setProperty('--main-accent', 'lightgreen');
        document.documentElement.style.setProperty('--second-accent', 'lightcoral');
        document.documentElement.style.setProperty('--button-color', '#3d3d3d');
        document.documentElement.style.setProperty('--button-color-hover', '#5f5e5e');

     } if (theme === 'red'){ 
        // Set multiple CSS variables
        document.documentElement.style.setProperty('--main-color', '#5f2a2a');
        document.documentElement.style.setProperty('--secondary-color', '#a74848');
        document.documentElement.style.setProperty('--font-color', 'rgb(255, 255, 255)');
        document.documentElement.style.setProperty('--third-color', 'black');
        document.documentElement.style.setProperty('--contrast-color', 'white');
        document.documentElement.style.setProperty('--main-accent', 'white');
        document.documentElement.style.setProperty('--second-accent', 'lightcoral');
        document.documentElement.style.setProperty('--button-color', 'black');
        document.documentElement.style.setProperty('--button-color-hover', 'rgb(39, 39, 39)');

     }
     if(theme === 'white'){ 
        document.documentElement.style.setProperty('--main-color', 'rgb(180, 180, 180)');
        document.documentElement.style.setProperty('--secondary-color', 'rgb(206, 201, 201)');
        document.documentElement.style.setProperty('--font-color', 'black');
        document.documentElement.style.setProperty('--third-color', 'rgb(119, 111, 111)');
        document.documentElement.style.setProperty('--contrast-color', 'black');
        document.documentElement.style.setProperty('--main-accent', 'black');
        document.documentElement.style.setProperty('--second-accent', 'red');
        document.documentElement.style.setProperty('--button-color', 'white');
        document.documentElement.style.setProperty('--button-color-hover', 'rgb(39, 39, 39)');
     }
     Cookies.set('theme', theme);

}

function Settings() {
    const dispatch = useDispatch();
    const handleSettingsToggle = () => { 
        dispatch(toggle())
    }

  return (
    <>
        <div className='background-color-full-dark border-radius-10px  popup z-index'  >
            <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={( )=> handleSettingsToggle()} >❌</button></div>
                <div className='flex-container-el padding-10px'> 
                <h3 className='padding-10px'>Settings</h3>
                <span className = "background-color-full-dark max-width" >Change Theme:</span>
                <select className='margin-top-10px background-color-full-dark max-width' onChange = {(e) => changeTheme(e.target.value)} >
                    <option value = "dark" selected disabled hidden>Select</option>
                    <option value = 'dark'>Midnight</option>
                    <option value = 'blue'>Royal Blue</option>
                    <option value = 'red'>Evening Red</option>
                    <option value = 'white'>Winter White</option>

                  
                </select>
            </div>
        </div>
    </>
  )
}

export default Settings
