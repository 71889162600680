import React from 'react'

function Leads_popup({setCurrentPopup, checkedHouseholds }) {
  return (
    <>
    <div className='background-color-full-dark border-radius-10px  popup z-index'  >
         <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick= { () => setCurrentPopup(null)}>❌</button></div>
             <div className='flex-container-el'> 
             <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width'onClick={() => checkedHouseholds.size === 2 ? setCurrentPopup(7) : alert("Must select only two households!")} >Merge Records</button>
             <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(1)}>Change Status</button>
             <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(2)}>Change Ownership</button>
             <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(3)}>Change/ Add Tags</button>
             <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(4)}>Change Vendors/Providers</button>
             <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(5)}>Delete Household</button>
             <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick ={() => setCurrentPopup(8)}>Change Branch</button>
             <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(6)}>Transfer Task </button>
             <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(9)}>Add Announcement </button>
         </div>
     </div>
  
    </>
  )
}

export default Leads_popup
