
import {React, useRef, useState, useReducer, useEffect} from 'react'
import Cookies from 'js-cookie'
const {fetchQuotes, postQuotes } = require('../fetches/fetch.js')

const Form = ({formData, fields, handleInputChange}) => { 
  return fields.map(({label, name}) => ( 
    <div key ={name} className=''>
      <label className = 'background-color-full-dark text-align-left margin-top-10px'>{label}*</label>
      <input 
        type = 'text'
        className="background-color-full-dark regularTextInput margin-top-5px max-width "
        name = {name}
        autoComplete = 'off'
        value = {formData[name]}
        onChange ={handleInputChange}
        required
      />
   
    </div>

  ))
}




function QuotePopupContainer({fn, api, setCurrentPopup, currentHouseholdId, fetchNotes  }){ 

  const initialState = null;
  const [fileName, setFileName] = useState('Click to upload file');
  const fileUpload = useRef();
  const company_name = useRef();
  const [formData, setFormData] = useState({
    c_number: null,
    is_column: null,
    insurance_company: null,
    length_with_carrier:null,
    bi_limits: null,
    amount_of_drivers: null,
    amount_of_cars: null,
    easy_pay: null,
    paid_in_full: null, 
    company_name: null
  }); 


 

  const handleAttachFile = (e) => {
    fileUpload.current.click();
    
  }

  const updateFileName = (e) => { 
    if(fileUpload.current?.files[0]){ 
      setFileName(fileUpload?.current?.files[0]?.name) 
    }   
  }


  const config = { 
    'Automobile': [ 
      { label: "C#", name: "c_number" },
      { label: "IS", name: "is_column" },
      { label: "Current Carrier", name: "insurance_company" },
      { label: "Length with Carrier", name: "length_with_carrier" },
      { label: 'BI Limits', name: 'bi_limits'}, 
      { label: 'Amount Of Drivers', name: 'amount_of_drivers'}, 
      { label: 'Year Home Built', name: 'year_home_built'}, 
      { label: 'Amount of Cars', name: 'amount_of_cars'}, 
      { label: 'Easy Pay', name: 'easy_pay'}, 
      { label: 'Paid In Full', name: 'paid_in_full'}

    ], 

    'General': [
      { label: "C#", name: "c_number" },
      { label: "IS", name: "is_column" },
      { label: "Current Carrier", name: "insurance_company" },
      { label: "Length with Carrier", name: "length_with_carrier" },
      { label: 'Easy Pay', name: 'easy_pay'}, 
      { label: 'Paid In Full', name: 'paid_in_full'} 

    ]
  }
 

  const handleInputChange = (event) => { 
    const {name, value } = event.target; 
 
    setFormData((prevFormData) => ({ 
      ...prevFormData, 
      [name]: value
    }))
  }

  
  const handleFormData = async(e) => { 
    e.preventDefault();
    const formDataObj = new FormData();
    if(e.target.checkValidity() !== false){

      if(formData ){ 
        /*Loop over formData obj and append to html FormDataObj */
        for( let key in formData){ 
          formDataObj.append(key, formData[key])
        }

        /*Append files to formDataObj */
        formDataObj.append('files', fileUpload.current.files[0])

        /*Append Household ID */ 
        formDataObj.append('household_id', Cookies.get('currentHouseholdId'))
       
        /*Append User ID */ 
        formDataObj.append('user_id', Cookies.get('userid'));
        const response = await  postQuotes(formDataObj)
        
        if(response.status === 500){ 
          return alert('Error');
        }else { 
          await fn();
          return setCurrentPopup(null);
        }
       }
       return alert('Missing File Upload')
    } 

  }

  const resetFormData = ( ) => { 
    setFormData({
      c_number: "",
      is_column: "",
      insurance_company: "",
      length_with_carrier: "",
      bi_limits: "",
      amount_of_drivers: "",
      amount_of_cars: "",
      easy_pay: "",
      paid_in_full: "", 
      company_name: ""
    });
  }
 
 

  function reducer(state, action){ 
    switch(action.type){ 
      case 'Automobile': 
       return config['Automobile'];
       case 'Motorcycle': 
       return config['Automobile']; 
       case '': 
       return null
      default: 
        return config['General'];
      
    }
  }

  const POST_QUOTES = async(QUOTE_OBJ) => { 

    const response = await fetch(`${api}/api/household/quotes?household_id=${currentHouseholdId}&user_id=${Cookies.get('userid')}`, {
      method: "POST",

      headers : { 
        'Content-Type': 'application/json',
      } ,
      body : JSON.stringify(QUOTE_OBJ)
     })
     return response;
  } 

  const insurance_companies = [
    "AIG",
    "Allstate",
    "Allstate Flood",
    "American Bankers Insurance Company",
    "American Integrity Insurance of Florida, Inc.",
    "American Reliable Insurance",
    "AmWins",
    "Auto-Owners Insurance",
    "Auto Owners",
    "Dairyland",
    "Cabrillo Coastal",
    "Citizens Property Insurance Corporation",
    "Chubb",
    "CNA",
    "Edison",
    "Federated National Insurance Company",
    "Florida Family Insurance Company",
    "Florida Peninsula Insurance Company",
    "Foremost Insurance",
    "Frontline Insurance",
    "Geico",
    "GoldenTrust Insurance",
    "Heritage Property & Casualty Insurance Company",
    "Infinity",
    "Jewelry Mutual",
    "Kemper",
    "Liberty Mutual",
    "Mapfre",
    "Monarch",
    "National General",
    "Progressive",
    "Safety Harbour",
    "Safeco",
    "Security First",
    "Slide",
    "Southern Oak Insurance",
    "Southern Owners",
    "State Farm",
    "Star Casualty",
    "Travelers",
    "Tower Hill Signature Insurance Company",
    "United Auto",
    "United Property and Casualty",
    "Universal Of North America",
    "USAA",
    "The General",
    "The Hartford",
    "AON Flood",
    "Beyond Floods", 
    
  
  ];


  const [formFields, dispatch] = useReducer(reducer, initialState);






  return (  
    <div className='background-color-full-dark border-radius-10px popup z-index  ' >
    <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick= {() => setCurrentPopup(null)}  >❌</button></div>
      <div className='flex-container-el padding-20px width-400px'> 
        <h3 className='text-align-left'> Add a New Quote</h3>
        <label className='text-align-left'>Quote Type*</label>
        <select className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width padding-left-none'  onChange = {(e)=> {dispatch({type: e.target.value}); resetFormData()}} >
          <option value=''>Select Quote Type</option>
          <option value='Automobile'>Automobile</option>
          <option value='Boat'>Boat</option>
          <option value='Motorcycle'>Motorcycle</option>
          <option value='Jewelry'>Jewelry</option>
          <option value='Home'>Home</option>
          <option value='Condo'>Condo</option>
          <option value='Renters'>Renters</option>
          <option value='PUP'>PUP</option>
          <option value='Health'>Health</option>
          <option value='General Liability'>General Liability</option>
          <option value='Business Owners Package'>Business Owners Package</option>
          <option value='Disability'>Disability</option>
          <option value='Life'>Life</option>
          <option value='Umbrella'>Umbrella</option>
          <option value='Event'>Event</option>
          <option value='Comprehensive Personal Liability'>Comprehensive Personal Liability</option>
          <option value='Flood'>Flood</option>
          <option value='Recreational Vehicles'>Recreational Vehicles</option>
          <option value='Mobile Home'>Mobile Home</option>
          <option value='Landlords'>Landlords</option>
          <option value='Annuity'>Annuity</option>
          <option value='Fixed Annuity'>Fixed Annuity</option>
          <option value='Variable Annuity'>Variable Annuity</option>
          <option value='Index Annuity'>Index Annuity</option>
          <option value='Excess Liability'>Excess Liability</option>
          <option value='Errors and Omissions'>Errors and Omissions</option>
          <option value='Directors and Officers'>Directors and Officers</option>
          <option value='Crime'>Crime</option>
          <option value='Surety Bond'>Surety Bond</option>
          <option value='Miscellaneous Bond'>Miscellaneous Bond</option>
          <option value='Package'>Package</option>
          <option value='Property'>Property</option>
          <option value='Condominium'>Condominium</option>
          <option value='Inland Marine'>Inland Marine</option>
          <option value='Installation Builders Risk'>Installation Builders Risk</option>
          <option value='Boat Owners'>Boat Owners</option>
          <option value='Property (Dwelling Fire)'>Property (Dwelling Fire)</option>
          <option value='Property (Off-Road Vehicles)'>Off-Road Vehicle</option>
          <option value='Small Commercial Package'>Small Commercial Package</option>
          <option value='Glass'>Glass</option>
          <option value='Schedule Personal Property'>Schedule Personal Property</option>
          <option value='Earthquake'>Earthquake</option>
          <option value='Long-Term Care'>Long-Term Care</option>
          <option value='NSF'>NSF</option>
          <option value='Mexican Travel'>Mexican Travel</option>
          <option value='Roadside'>Roadside</option>
          <option value='Excess and Surplus'>Excess and Surplus</option>
          <option value='Builders Risk'>Builder's Risk</option>
          <option value='Pet Health'>Pet Health</option>
          <option value='Contract'>Contract</option>
          <option value='OTHER'>OTHER</option>
          <option value='Windowstorm'>Windowstorm</option>
          <option value='Workers Comp'>Workers Comp</option>
        </select>
        <form onSubmit={handleFormData} className="max-width">
          <div className='overflow-wrapper padding-5px'>
        {formFields && (
          <Form
            formData={formData}
            fields={formFields}
            handleInputChange={handleInputChange}
          />
     
        )}
          </div>
        <label className='text-align-left'>Company Name*</label>
        <select ref ={company_name} onChange = {handleInputChange} required name = 'company_name' className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width padding-left-none'>
          <option>Select Company Name</option>
          {insurance_companies && insurance_companies.map(company => ( 
            
            <option value = {company}>{company}</option>
          ))}
        </select>
        <label>Quoting Document</label>
         <div className='upload-div margin-top-5px max-width height-200px flex-container-el' onClick ={(e) => handleAttachFile (e)}>
                <span>{fileName ? fileName : "Click me to attach a file!"}</span>
                 <input onChange = {updateFileName} ref = {fileUpload} type = 'file' hidden />
          </div>
       <button type="submit" className='max-width green-submit-btn margin-top-10px'>Submit</button>
        </form>
      </div>
    </div>
      

  )
}
export default QuotePopupContainer

